import React, { useState } from 'react'
import UploadCloud from "../../../../assets/svg/XMLModal/upload-cloud.svg";

export default function Archive() {
    const [file, setFile] = useState(null)

    const onFileDrop = (e) => {
        const newFile = e.target.files[0]

        if (newFile) {
            const updateList = [...file, newFile]
            setFile(updateList)
        }
    }

    // const fileRemove = (file) => {
    //     const updatedList = [...file]
    //     updatedList.splice(file.indexOf(file), 1)
    //     setFile(updatedList)
    // }

    return (
        <div className="flex flex-col justify-center px-8 py-4">
            <h3 className="font-bold text-lg">Carregue seu arquivo XML</h3>

            <>
                <div className="relative flex flex-col justify-center items-center border-dashed border-2 border-gray-500 bg-gray-500 bg-opacity-10 px-3 h-72 my-3">
                    <input type="file" className="absolute w-full h-full cursor-pointer top-0 left-0 opacity-0" value="" onChange={onFileDrop} />

                    <img src={UploadCloud} alt="Upload Cloud Icon" className="my-2 w-20" />

                    <p className="font-semibold text-md">Arraste e solte seu arquivo aqui</p>
                </div>

                <div className="relative flex py-3 justify-center items-center">
                    <div className="flex w-1/4 border-t border-gray-400"></div>
                    <span className="flex-shrink mx-4 font-semibold text-gray-500">ou</span>
                    <div className="flex w-1/4 border-t border-gray-400"></div>
                </div>

                <div className="flex flex-col justify-center items-center my-3">
                    <button className="relative border-[2px] border-soulog-900 text-soulog-900 text-sm font-semibold rounded-md px-4 py-2">
                        <input type="file" className="absolute w-full h-full cursor-pointer top-0 left-0 opacity-0" value="" onChange={onFileDrop} />
                        Selecionar um arquivo na sua máquina
                    </button>
                </div>
            </>
        </div>
    )
}
