import { useState } from "react";
import Stepper from "./Stepper.component";
import StepperControl from "./StepperControl.component";
import PedidoVenda from "./steps/PedidoVenda.component";
import CheckProducts from "./steps/CheckProducts.component";
import Validations from "./steps/Validations.component";
import Archive from "./steps/Archive.component";

export default function PedidoModal({ setOpenModal, setUpdatePedido, editPedido }) {
    const [currentStep, setCurrentStep] = useState(1);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [objPedido, setObjPedido] = useState({
        pedidoBling: '',
        isPedidoBling: true,
        itensRemovido: [],
        clienteId: localStorage.getItem("@Cliente:id"),
        idPedidoBling: null,
        idCanalVenda: null,
        idContato: null,
        aprovarPedido: false,
        pedidoInterno: {
            canalVenda: null
        }
    });

    const [loading, setLoading] = useState(false);
    const [infoAdicional, setInfoAdicional] = useState({});


    const steps = [
        { title: "Pedido", desc: "Preencha as informações" },
        { title: "Conferir produtos", desc: "Confirme os produtos" },
        { title: "Validação", desc: "Validação estoque" },
        { title: "Anexos", desc: "Anexse os arquivos solicitados" }
    ]

    const displayStep = (step) => {
        switch (step) {
            case 1:
                return <PedidoVenda loading={loading} setLoading={setLoading} setCurrentStep={setCurrentStep} editPedido={editPedido} objPedido={objPedido} setObjPedido={setObjPedido} setErrorMsg={setErrorMsg} setError={setError} />
            case 2:
                return <CheckProducts objPedido={objPedido} setObjPedido={setObjPedido} setErrorMsg={setErrorMsg} setError={setError} />
            case 3:
                return <Validations objPedido={objPedido} setObjPedido={setObjPedido} setErrorMsg={setErrorMsg} setError={setError} />
            case 4:
                return <Archive objPedido={objPedido} setObjPedido={setObjPedido} setErrorMsg={setErrorMsg} setError={setError} />
            default:
        }
    }

    const handleClick = (direction) => {
        let newStep = currentStep;
        setError(false);
        direction === "next" ? newStep++ : newStep--

        newStep > 0 && newStep <= steps.length && setCurrentStep(newStep)
    }

    return (
        <div className="flex flex-col h-[95%] justify-center w-[95%] xl:w-[95] mx-auto rounded-2xl px-5 bg-white drop-shadow-2xl">
            {/* Close button */}
            <span
                className="absolute -top-4 -right-4 bg-white drop-shadow-xl px-3 py-2.5 rounded-md text-soulog-900 font-bold cursor-pointer"
                onClick={() => setOpenModal()}
            >
                X
            </span>

            {/* Stepper */}
            <div className="xl:mt-5">
                <Stepper steps={steps} currentStep={currentStep} />
            </div>

            <div className="overflow-y-auto w-full h-full">
                {displayStep(currentStep)}
            </div>

            {error &&
                <div className="xl:mt-5 w-full">
                    <span className="flex flex-col justify-center text-center bg-red-300 py-2 px-3 rounded-2">
                        {errorMsg}
                    </span>
                </div>
            }

            {/* Navigation Controls */}
            <StepperControl
                handleClick={handleClick}
                currentStep={currentStep}
                steps={steps}
                setOpenModal={setOpenModal}
                error={error}
                setError={setError}
                setErrorMsg={setErrorMsg}
                setObjPedido={setObjPedido}
                infoAdicional={infoAdicional}
                objPedido={objPedido}
                setUpdatePedido={setUpdatePedido}
                setLoading={setLoading}
                loading={loading}
                editPedido={editPedido}
            />
        </div>
    )
}