import AlertTriangleRed from "../../assets/svg/menu/alert-triangle-red.svg";

export default function IntegrationStatus({ status }) {
    return (
        <>
            {status === "success" && (
                <span className="hidden my-1 lg:flex flex-row justify-center items-center bg-green-100 rounded-xl px-3 py-1 font-normal text-green-700 text-sm mr-5">
                    <span className="bg-green-700 rounded-full w-3 h-3 mr-2"></span>
                    Integração WMS online
                </span>
            )}

            {status === "error" && (
                <span className="hidden my-1 lg:flex flex-row justify-center items-center bg-red-100 rounded-xl px-3 py-1 font-normal text-red-700 text-sm mr-5">
                    <span className="bg-red-700 rounded-full w-3 h-3"></span>
                    <img src={AlertTriangleRed} alt="Alert Triangle" className="mr-2 ml-3" />
                    Integração WMS offline
                </span>
            )}
        </>
    )
}