export default function PedidoSaida({ openSaida }) {
    return (
        <div className="relative flex flex-col justify-center lg:w-auto mx-auto rounded-2xl px-5 py-5 bg-white drop-shadow-2xl">
            <span
                className="absolute -top-4 -right-4 bg-white drop-shadow-xl px-3 py-2.5 rounded-md text-soulog-900 font-bold cursor-pointer"
                onClick={() => openSaida()}
            >
                X
            </span>

            <h3 className="text-sm font-semibold md:w-[360px]">Por favor adicione um pedido em seu ERP e informe o número do mesmo no campo abaixo</h3>
            <span className="text-sm">Ou insira um pedido no sistema</span>

            <div className="flex flex-col text-sm my-4">
                <label htmlFor="pedido">Número do pedido</label>
                <input type="text" className="p-2 border-black border-[1px] rounded-md my-2"/>
            </div>

            <button 
                type="submit"
                className="w-full py-2.5 bg-soulog-900 text-white rounded-md text-sm font-semibold"
            >
                Inserir pedido
            </button>
        </div>
    )
}