import React from 'react'
import { useLocation } from 'react-router-dom'
import Sidebar from './Sidebar.component'

export default function SidebarLayout() {
    const location = useLocation()

    if(location.pathname === "/login") return null;

    return (
        <div className="flex flex-row">
            <Sidebar/>
        </div>
    )
}
