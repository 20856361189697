import { useState } from "react";

export default function TableHistorico() {
    return (
        <div className="overflow-x-auto overflow-y-auto h-32">
            <table className="w-full text-sm text-left">
                <thead className="text-xs text-white uppercase bg-soulog-900">
                    <tr>
                        <th scope="col" className="py-3 px-12">
                            Data
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Status Anterior
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b-2 border-gray-200 xs:text-xs md:text-md">
                        <td className="xs:py-2 md:py-4 px-12">
                            22/10/2022
                        </td>
                        <td className="xs:py-2 md:py-4 px-6 uppercase">
                            Aguardando Etiqueta
                        </td>
                        <td className="xs:py-2 md:py-4 px-6">
                            Novo
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}