import { useState } from "react"
import TableHistorico from "./TableHistorico.component"
import TableProdQtd from "./TableProdQtd.component"

export default function OrigemModal({ openModal }) {
    const [canalDeVenda, setCanalDeVenda] = useState('')
    const [nf, setNf] = useState('')
    const [nPedido, setNPedido] = useState('')

    return (
        <div className="relative flex flex-col items-center justify-center w-[85%] xl:w-[60%] mx-auto rounded-2xl px-4 py-5 bg-white drop-shadow-2xl">
            <span
                className="absolute -top-4 -right-4 bg-white drop-shadow-xl px-3 py-2.5 rounded-md text-soulog-900 font-bold cursor-pointer"
                onClick={() => openModal()}
            >
                X
            </span>

            <div className="flex flex-col gap-x-3 gap-y-3 w-full">
                <h3 className="font-bold text-lg mb-2">Origem</h3>

                <form className="flex flex-col">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-4 gap-y-3 w-full">
                        <div className="flex flex-col w-full">
                            <label htmlFor="canaldevenda" className="font-semibold text-sm">Canal de Venda</label>
                            <input
                                type="number"
                                id="canaldevenda"
                                className="border-[1px] bg-gray-200 rounded-md my-1 p-2"
                                value={canalDeVenda}
                                onChange={(e) => setCanalDeVenda(e.target.value)}
                                disabled={true}
                            />
                        </div>

                        <div className="flex flex-col w-full">
                            <label htmlFor="notafiscal" className="font-semibold text-sm">N° NF</label>
                            <input
                                type="number"
                                id="notafiscal"
                                value={nf}
                                onChange={(e) => setNf(e.target.value)}
                                className="border-[1px] bg-gray-200 rounded-md my-1 p-2"
                                disabled={true}
                            />
                        </div>

                        <div className="flex flex-col w-full">
                            <label htmlFor="endereco" className="font-semibold text-sm">N° PEDIDO</label>
                            <input
                                type="number"
                                id="endereco"
                                value={nPedido}
                                onChange={(e) => setNPedido(e.target.value)}
                                className="border-[1px] bg-gray-200 rounded-md my-1 p-2"
                                disabled={true}
                            />
                        </div>
                    </div>
                </form>
            </div>

            <div className="flex flex-col w-full my-2">
                <h3 className="font-bold mb-2">Histórico de transição entre status</h3>

                <TableHistorico/>
            </div>

            <div className="flex flex-col w-full my-2">
                <h3 className="font-bold mb-2">Produtos e Quantidades</h3>

                <TableProdQtd/>
            </div>

            <button
                type="button"
                className="w-40 py-2.5 border-2 border-soulog-900 text-soulog-900 rounded-md text-sm font-semibold my-3"
                onClick={() => openModal()}
            >
                Fechar
            </button>
        </div>
    )
}