import { Routes, Route, BrowserRouter, Link } from "react-router-dom";
import Home from "./view/Home";
import Login from "./view/Login";
import Entrada from "./view/Entrada";
import Breve from "./view/Breve";
import { RequireAuth } from './context/Auth/RequireAuth';
import Pedidos from "./view/Pedidos";
import Estoque from "./view/Estoque";
import Produtos from "./view/Produtos";
import B2B from "./view/B2B"

import './app.css'
import SidebarLayout from "./components/Layout/SidebarLayout.component";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export default function App() {
    return (
        <BrowserRouter>
            <SidebarLayout/>
            <Routes>
                <Route path="/login" element={<Login />} />
                    <Route path="/" element={<RequireAuth><Home /></RequireAuth>} />
                    <Route path="/home" element={<RequireAuth><Home /></RequireAuth>} />
                    <Route path="/entrada" element={<RequireAuth><Entrada /></RequireAuth>} />
                    <Route path="/pedidos" element={<RequireAuth><Pedidos /></RequireAuth>} />

                    <Route path="/estoque" element={<RequireAuth><Breve previsto="22/02/2023"/></RequireAuth>} />
                    <Route path="/estoque/:id" element={<Estoque />} />

                    <Route path="/devolucao" element={<RequireAuth><Breve previsto="30/03/2023"/></RequireAuth>} />     
                    <Route path="/sepb2b" element={<RequireAuth><B2B /></RequireAuth>} />

                    {/*  <Route path="/produtos" element={<RequireAuth><Breve previsto="22/03/2023"/></RequireAuth>} /> */}
                    <Route path="/produtos" element={<Produtos />} />

                    <Route path="/integracoes" element={<RequireAuth><Breve previsto="05/04/2023"/></RequireAuth>} />
                    <Route path="/empresa" element={<RequireAuth><Breve previsto="05/04/2023"/></RequireAuth>} />
                    <Route path="/relatorios" element={<RequireAuth><Breve previsto="05/04/2023"/></RequireAuth>} />
                    <Route path="/suporte" element={<Suporte/>} />
                    
            </Routes>
            <ToastContainer />
        </BrowserRouter>
    );
}

function Suporte() {
    // 👇️ redirect to external URL
    window.location.replace('https://ajuda.soulog.com.br/');
  
    return null;
  }