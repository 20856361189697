
import { useState, useContext, useEffect } from "react";
import { useApi } from "../../hooks/useApi";
import { getStatusPedidoInterno } from "../../helpers/functionsHelps";
import moment from "moment";
import FileText from "../../assets/svg/file-text.svg";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

export default function TableB2B(props) {

    const [tableItems, setTableItems] = useState([]);
    const [totalItems, setTotalItems] = useState([]);

    const api = useApi();

    const filterItems = () => {

        let data = [...totalItems];
        setTableItems(data);
        props.passTableItems(data);

    };

    const getData = async () => {
        try {
            const response = await api.listaPedidosB2B();
            if (response.status == 200) {
                if (response.data.length > 0) {
                    setTotalItems(response.data);
                    filterItems();
                }

            }
        } catch (error) {
            console.log(error);
        }

    };

    useEffect(() => {
        if (totalItems.length <= 0) {
            getData();
        } else {
            filterItems();
        }
    }, [props.toggle, totalItems]);


    useEffect(() => {
        if (props.checkFilter == true && props.filterStatus != -1) {
            let itens = [...totalItems];

            setTableItems(itens.filter(x => x.status == props.filterStatus));

        } else {
            filterItems();
        }
    }, [props.checkFilter])

    useEffect(() => {
        console.log(props.updatePedido)
        if (props.updatePedido == true) {
            getData();
        }
    }, [props.updatePedido]);

    const handleDocumentacao = async (item) => {
        let retorno = await MySwal.fire({
            icon: 'warning',
            title: 'Confirmação Lista Mercado Livre.',
            text: 'Caso possua uma lista do MercadoLivre prossiga, casso não ignore.',
            confirmButtonColor: "#00948b",
            showCancelButton: true,
            cancelButtonText: 'Ignorar',
            allowEscapeKey: false,
            allowOutsideClick: false
        });

        console.log(item);

        if (retorno.isConfirmed == true) {
            props.openDocumentacaoModal(item);
        }

        if (retorno.isDismissed == true) {

        }
    }


    return (
        <div className="overflow-x-auto mb-8">
            <table className="w-full text-sm text-left">
                <thead className="text-xs text-white uppercase bg-soulog-900">
                    <tr>
                        <th scope="col" className="py-3 px-12">
                            N° Pedido
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Canal de Venda
                        </th>
                        <th scope="col" className="py-3 px-6">
                            N° Itens
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Data Programado
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Data Prevista
                        </th>
                        <th scope="col" className="py-3 px-16">
                            Ticket
                        </th>
                        <th scope="col" className="py-3 px-16">
                            Status
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tableItems.filter(x => props.checkFilter ? true : true).map((item, index) => (
                        <tr key={item.idPedidoInterno} className="bg-white border-b-2 border-gray-200">
                            <td className="py-4 px-12">
                                {item.status == 0 ?
                                    (
                                        <span className="font-bold underline cursor-pointer" onClick={() => props.setEditPedido(item.numeroPedido)}>
                                            {item.numeroPedido}
                                        </span>

                                    ) : item.numeroPedido
                                }
                            </td>
                            <td className="py-4 px-6 uppercase">
                                {item.canalVenda}
                            </td>
                            <td className="py-4 px-6">
                                {item.quantidadeItens}
                            </td>
                            <td className="py-4 px-6">
                                {moment(item.dataProgramado).format("DD/MM/YYYY")}
                            </td>
                            <td className="py-4 px-6">
                                {item.dataPrevisto != null ? moment(item.dataPrevisto).format("DD/MM/YYYY") : ""}
                            </td>
                            <td className="py-4 px-6">
                                <a className="underline" target="_blank" href={"https://soulog.movidesk.com/Ticket/Edit/" + item.ticketMovidesk}>
                                    {item.ticketMovidesk}
                                </a>
                            </td>
                            <td className="py-4 px-6">
                                {getStatusPedidoInterno(item)}
                            </td>
                            <td className="flex flex-row py-6 px-8 cursor-pointer">
                                <img className="px-2" src={FileText} alt="Eye Icon"
                                    onClick={() => props.openDocumentacaoModal(item)}
                                    hidden={item.status != 4}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}