import { useState } from "react";

export default function Form({ setObjXml, objXml }) {
    const [formData, setFormData] = useState({
        nf: objXml.entrada.numeroNF,
        nomeFornecedor: objXml.fornecedor.nome,
        idFornecedor: objXml.fornecedor.blingId,
        numVolumes: objXml.entrada.totalVolumesInformado
    });

    return (
        <form className="flex flex-col my-4">
            <div className="grid grid-cols-2 xl:grid-cols-4 gap-x-4 w-full">
                <div className="flex flex-col w-full">
                    <label htmlFor="nf" className="font-semibold text-sm">N° NF</label>
                    <input
                        type="number"
                        id="nf"
                        value={formData.nf}
                        onChange={(e) => setFormData({ ...formData, nf: e.target.value })}
                        className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                        disabled={true}
                    />
                </div>

                <div className="flex flex-col ml-0 w-full">
                    <label htmlFor="nomeFornecedor" className="font-semibold text-sm">Nome do Fornecedor</label>
                    <input
                        type="text"
                        id="nomeFornecedor"
                        value={formData.nomeFornecedor}
                        onChange={(e) => setFormData({ ...formData, nomeFornecedor: e.target.value })}
                        className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                        disabled={true}
                    />
                </div>

                <div className="flex flex-col ml-0 w-full">
                    <label htmlFor="idFornecedor" className="font-semibold text-sm">ID Fornecedor</label>
                    <input
                        type="text"
                        id="idFornecedor"
                        value={formData.idFornecedor}
                        onChange={(e) => setFormData({ ...formData, nomeFornecedor: e.target.value })}
                        className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                        disabled={true}
                    />
                </div>

                <div className="flex flex-col ml-0 w-full">
                    <label htmlFor="numVolume" className="font-semibold text-sm">N° de Volumes</label>
                    <input
                        type="text"
                        id="numVolume"
                        value={formData.numVolumes}
                        onChange={(e) => setFormData({ ...formData, nomeFornecedor: e.target.value })}
                        className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                        disabled={true}
                    />
                </div>
            </div>
        </form>
    )
}