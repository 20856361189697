import React from 'react'
import TableValidarERP from '../../Table/TableValidarERP.component'
import Form from '../Form.component'

export default function ValidateERP({ setObjXml, objXml }) {
    return (
        <div className="px-8 py-4">
            <h3 className="font-bold text-lg">Favor realizar a validação final dos itens</h3>

            <Form setObjXml={setObjXml} objXml={objXml}/>

            <TableValidarERP setObjXml={setObjXml} objXml={objXml}/>
        </div>
    )
}
