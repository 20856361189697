import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export default function TableValidacaoProdutosB2B({ setChecked, objPedido, setObjPedido, setProduto, setEditProduto }) {
    const [estoqueIdeal, setEstoqueIdeal] = useState(false);

    const handleRemoveRow = (row, index) => {
        //setItensRemovido([...itensRemovido, row.id]);

        //let itensPedido = objPedido.pedidoInterno.itensPedidoInterno.filter(x => x.id !== row.id);
        //let itensPedido = objPedido.pedidoInterno.itensPedidoInterno.splice(index, 1);

        const obj = { ...objPedido };

        if (row.id > 0)
            obj.itensRemovido.push(row.id);

        obj.pedidoInterno.itensPedidoInterno.splice(index, 1);
        //obj.itensRemovido = itensRemovido;
        setObjPedido(obj);

        console.log(row);
    }

    const handleEditRow = (row, index) => {
        setProduto({ id: row.id, index: index, codigoBling: row.produtos.codigo, quantidade: row.quantidade, valor: row.valorUnitario })
        setEditProduto(true);
    };

    const RemoveIcon = ({ onClick }) => {
        return (
            <Tippy content="Remover produto.">
                <FontAwesomeIcon
                    icon={faTrash}
                    className="text-2xl text-red-600 cursor-pointer focus:outline-none"
                    onClick={onClick}
                />
            </Tippy>
        );
    };

    const EditIcon = ({ onClick }) => {
        return (
            <Tippy content="Editar produto.">
                <FontAwesomeIcon
                    icon={faPenToSquare}
                    className="text-2xl text-blue-600 cursor-pointer focus:outline-none"
                    onClick={onClick}
                />
            </Tippy>
        );
    };

    useEffect(() => {
        setEstoqueIdeal(localStorage.getItem("@Cliente:estoqueIdeal"))
    }, []);

    const handleChangeInput = (e, index) => {
        const obj = { ...objPedido };
        let value = e.target.value;

        obj.pedidoInterno.itensPedidoInterno[index].etiquetaFull = value;
        setObjPedido(obj);


        console.log(e.target.value, index, obj.pedidoInterno.itensPedidoInterno[index])
    }

    return (
        <div className="overflow-x-auto overflow-y-auto mt-4">
            <table className="w-full text-[11px] text-left ">
                <thead className="text-white uppercase bg-soulog-900">
                    <tr>
                        <th scope="col" className="py-3 px-12">
                            Código
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Descrição
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Qtd Itens
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Estoque Atual
                        </th>
                        {estoqueIdeal ? (
                            <th scope="col" className="py-3 px-6">
                                Estoque Ideal
                            </th>
                        ) : null}

                        <th scope="col" className="py-3 px-6">
                            Valor Unitário
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Valor Total
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Código Etiqueta FULL
                        </th>
                        <th scope="col" className="py-3 px-6">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {objPedido.pedidoInterno.itensPedidoInterno.map((item, index) => (
                        <tr className={`border-b-2 border-gray-200 ${item.isEstoque ? 'bg-green-100' : 'bg-red-100'}`} key={index}>
                            <td className="py-4 px-12">
                                {item.produtos.codigo}
                            </td>
                            <td className="py-4 px-6 uppercase">
                                {item.produtos.descricao}
                            </td>
                            <td className="py-4 px-6">
                                {item.quantidade}
                            </td>
                            <td className="py-4 px-6">
                                {item.estoque}
                            </td>
                            {estoqueIdeal ? (
                                <td className="py-4 px-6">
                                    {item.estoqueIdeal}
                                </td>
                            ) : null}
                            <td className="py-4 px-6">
                                {item.valorUnitario.toFixed(2)}
                            </td>
                            <td className="py-4 px-6">
                                {item.valorTotal.toFixed(2)}
                            </td>
                            <td className="py-4 px-6">
                                <input type="text" className="p-2 border-solid border-2 font-bold" value={item.etiquetaFull} onChange={(e) => handleChangeInput(e, index)} />
                            </td>
                            <td className="py-4 px-6">
                                {!item.isEstoque ? (
                                    <>
                                        <EditIcon onClick={() => handleEditRow(item, index)} />
                                        <span className="mr-1 ml-1"></span>
                                        <RemoveIcon onClick={() => handleRemoveRow(item, index)} />
                                    </>
                                ) : null}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}