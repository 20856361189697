import { useState } from "react";

export default function TableProdQtd() {
    return(
        <div className="overflow-x-auto overflow-y-auto h-32">
            <table className="w-full text-sm text-left">
                <thead className="text-xs text-white uppercase bg-soulog-900">
                    <tr>
                        <th scope="col" className="py-3 px-12">
                            Código
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Descrição
                        </th>
                        <th scope="col" className="py-3 px-6">
                            QTD ITENS
                        </th>
                        <th scope="col" className="py-3 px-6">
                            PACOTE?
                        </th>
                        <th scope="col" className="py-3 px-6">
                            QTD PACOTE
                        </th>
                        <th scope="col" className="py-3 px-6">
                            CÓDIGO ERP
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b-2 border-gray-200 xs:text-xs md:text-md">
                        <td className="xs:py-2 md:py-4 px-12">
                            1234
                        </td>
                        <td className="xs:py-2 md:py-4 px-6 uppercase">
                            AC CR ORG NATIVE DEMERARA 12PCS X 1KG
                        </td>
                        <td className="xs:py-2 md:py-4 px-6">
                            10
                        </td>
                        <td className="xs:py-2 md:py-4 px-6">
                            <input type="checkbox"/>
                        </td>
                        <td className="xs:py-2 md:py-4 px-6">
                            5
                        </td>
                        <td className="xs:py-2 md:py-4 px-6">
                            AHF1654HGD784KL1561
                        </td>
                    </tr>

                    {/* {tableItems.map(item => (
                        <tr className="bg-white border-b-2 border-gray-200">
                            <td className="py-4 px-12">
                                {item.nf}
                            </td>
                            <td className="py-4 px-6 uppercase">
                                {item.fornecedor}
                            </td>
                            <td className="py-4 px-6">
                                {item.numItems}
                            </td>
                            <td className="py-4 px-6">
                                {item.numVolumes}
                            </td>
                            <td className="py-4 px-6">
                                {item.ticket}
                            </td>
                            <td className="py-4 px-6">
                                <span className="bg-green-300 py-2 px-3 rounded-3xl">
                                    Registrada
                                </span>
                            </td>
                            <td className="py-4 px-6">
                                {item.prazo}
                            </td>
                            <td className="py-4 px-12 cursor-pointer">
                                <img src={FileText} alt="Eye Icon" onClick={() => setOpenDetails(prevState => !prevState)} />
                            </td>
                        </tr>
                    ))} */}
                </tbody>
            </table>
        </div>
    )
}