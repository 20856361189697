import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/Auth/AuthContext";
import 
{
    getLengthEntradasArmazenadas, 
    getLengthEntradasArmazenadasRessalva, 
    getLengthEntradasConferencia, 
    getLengthEntradasPendencias, 
    getLengthEntradasRecebidas, 
    getLengthEntradasRegistradas, 
    getLengthStatusEntradas } from "../helpers/functionsHelps";

// Components
import EvolutionBox from "../components/EvolutionBox/EvolutionBox.component";
import InfoBox from "../components/InfoBox/InfoBox.component";
import Items from "../components/InfoBox/Items.component";
import Layout from "../components/Layout/Layout.component";
import ReportBox from "../components/ReportBox/ReportBox.component";

// Items logos
import EditIcon from "../assets/svg/infobox/edit-3.svg";
import CheckIcon from "../assets/svg/check.svg";
import EyeIcon from "../assets/svg/infobox/eye.svg";
import ClipboardIcon from "../assets/svg/infobox/clipboard.svg";
import BoxIcon from "../assets/svg/infobox/box.svg";
import AlertTriangleIcon from "../assets/svg/infobox/alert-triangle.svg";
import ClockIcon from "../assets/svg/infobox/clock.svg"
import TruckIcon from "../assets/svg/infobox/truck.svg";
import AlertErro from "../assets/svg/menu/erro-triangle-red.svg"

import { useApi } from "../hooks/useApi";

export default function Home() {
    const [entradas, setEntradas] = useState([{}]);
    const [statusPedidos, setStatusPedidos] = useState({});

    const auth = useContext(AuthContext);
    const api = useApi();

    const [permissoes, setPermissoes] = useState({})

    useEffect(() => {
        setPermissoes(JSON.parse(localStorage.getItem("@Cliente:permissoes")));
    }, [])

    const getData = async ()=> {
        try {
          let response = await api.buscarEntradas();
          if(response.status == 200) {
            setEntradas(response.data)
          }

          response = await api.getStatusPedidosByClienteId();
          if(response.status == 200) {
            setStatusPedidos(response.data)
          }

        } catch (error) {
          console.log(error)
        }
    
      }

    useEffect(() => {
        getData()
      }, []);

    return (
        <Layout title="Home">
            <InfoBox spacing="xl:grid-cols-5" title={"Notas de entrada"}>

                <Items
                    icon={AlertErro}
                    quantity= {getLengthEntradasPendencias(entradas) ?? 0}
                    title={getLengthEntradasPendencias(entradas) > 1 ? "Pendências" : "Pendência"}
                    error={true}
                    visible={true}
                />

                <Items
                    icon={EditIcon}
                    quantity= {getLengthEntradasRegistradas(entradas) ?? 0}
                    title={getLengthEntradasRegistradas(entradas) > 1 ? "Previstas" : "Prevista"}
                    subtitle={getLengthEntradasRegistradas(entradas) > 1 ? "XML Anexados" : "XML Anexado"}
                    visible={true}
                />

                <Items
                    icon={CheckIcon}
                    quantity={getLengthEntradasRecebidas(entradas) ?? 0}
                    title={getLengthEntradasRecebidas(entradas) > 1 ? "Recebidas" : "Recebida"}
                    visible={true}
                />

                <Items
                    icon={EyeIcon}
                    quantity={getLengthEntradasConferencia(entradas) ?? 0}
                    title="Em conferência"
                    visible={true}
                />
                <Items
                    icon={BoxIcon}
                    quantity={getLengthEntradasArmazenadas(entradas) ?? 0}
                    title={getLengthEntradasArmazenadas(entradas) > 1 ? "Armazenadas" : "Armazenada"}
                    visible={true}
                />
            </InfoBox>

            <InfoBox spacing="xl:grid-cols-7" title={"Pedidos de venda"}>
                <Items
                    icon={AlertErro}
                    quantity={statusPedidos.aguardandoEtiqueta ?? 0}
                    title="Aguardando etiqueta" 
                    error={true} 
                    visible={true}
                />

                <Items
                    icon={CheckIcon}
                    quantity={statusPedidos.novos ?? 0}
                    title="Novos"
                    visible={true}
                />

                <Items
                    icon={BoxIcon}
                    quantity={statusPedidos.separando ?? 0}
                    title="Separando"
                    visible={true}
                />

                <Items
                    icon={BoxIcon}
                    quantity={statusPedidos.embalando ?? 0}
                    title="Embalando"
                    visible={true}
                />

                <Items
                    icon={ClockIcon}
                    quantity={statusPedidos.aguardandoColeta ?? 0}
                    title="Aguardando coleta"
                    visible={true}
                />

                <Items
                    icon={TruckIcon}
                    quantity={statusPedidos.expedidos ?? 0}
                    title="Expedidos"
                    visible={true}
                />
                <Items
                    icon={AlertErro}
                    quantity={statusPedidos.semEstoque ?? 0}
                    title="Sem estoque"
                    error={true}
                    visible={permissoes.estoque}
                />

                { /*
                <Items
                    icon={AlertErro}
                    quantity={statusPedidos.erros ?? 0}
                    title="Erros"
                    error={true}
                />
                */}
            </InfoBox>
                
            { /*

            <div className="flex flex-col md:flex-row md:flex-wrap xl:flex-nowrap justify-between">
                
                <ReportBox title="Vendas por estado" />

               
                <ReportBox title="Vendas por canal" margin="xl:mx-6" />
                <ReportBox title="Vendas por categoria" />
            </div>

            <div className="flex flex-col">
                
                <EvolutionBox title="Evolução do faturamento" />
                <EvolutionBox title="Evolução do faturamento por canal" />
            </div>
            */}
        </Layout>
    )
}