import FileText from "../../assets/svg/file-text.svg";
import Eye from "../../assets/svg/pedidos/eye.svg";
import { useState, useContext, useEffect } from "react";
import { useApi } from "../../hooks/useApi";
import { getStatusEntrada, getAtrasoEntrada } from "../../helpers/functionsHelps";


export default function TableEntrada(props) {

    const [tableItems, setTableItems] = useState([]);
    const [totalItems, setTotalItems] = useState([]);

    

    const api = useApi();

    const filterItems = () => {

        let data = [...totalItems ];
        setTableItems(data);
        props.passTableItems(data);

        /*if(props.toggle == true) {
            let data = [...totalItems ];
            data = data.filter(x => x.status != 8 && x.status != 9);
            setTableItems(data);
            props.passTableItems(data);
        } else {
            let data = [...totalItems ];
            data = data.filter(x => x.status == 8 || x.status == 9);
            setTableItems(data);
            props.passTableItems(data);
        }*/
    };

    const getData = async ()=> {
        try {
          const response = await api.buscarEntradas();
          if(response.status == 200) {
            if(response.data.length > 0) {
                setTotalItems(response.data);
                filterItems();
            }
            
          }
        } catch (error) {
          console.log(error);
        }
    
      };

    useEffect(() => {
        if(totalItems.length <= 0) {
            getData();
        } else {
            filterItems();
        }
      }, [props.toggle, totalItems, props.updateEntrada, props.openRessalvaModal]);

      useEffect(() => {
            getData();
      }, [props.updateEntrada, props.openRessalvaModal]);

    useEffect(() => {
        if(props.checkFilter == true && props.filterStatus != 0) {
            let itens = [...totalItems];
            
            if(props.filterStatus == 8) {
                setTableItems(itens.filter(x => x.status == 8 || x.status == 9));
            } else {
                setTableItems(itens.filter(x => x.status == props.filterStatus));
             }
            
        } else {
            filterItems();
        }
    }, [props.checkFilter])

    
    const handleGeraPdfClick = async (xml) => {

        let retorno = await api.geraDanfe(xml);

        let pdfWindow = window.open("")
        pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(retorno.data) + "'></iframe>");
    }

    

    return (
        <div className="overflow-x-auto mb-8">
            <table className="w-full text-sm text-left">
                <thead className="text-xs text-white uppercase bg-soulog-900">
                    <tr>
                        <th scope="col" className="py-3 px-12">
                            N° NF
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Fornecedor
                        </th>
                        <th scope="col" className="py-3 px-6">
                            N° Itens
                        </th>
                        <th scope="col" className="py-3 px-6">
                            N° Volumes
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Ticket
                        </th>
                        <th scope="col" className="py-3 px-16">
                            Status
                        </th>
                        <th scope="col" className="py-3 px-16">
                            Status Conf
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Detalhes
                        </th>
                    </tr>
                </thead>
                <tbody>
                     {tableItems.filter(x => props.checkFilter ? true : x.status != 5).map((item, index) => (
                        <tr key={index} className="bg-white border-b-2 border-gray-200">
                            <td className="py-4 px-12">
                                {item.numeroNF}
                            </td>
                            <td className="py-4 px-6 uppercase">
                                {item.nomeFornecedor}
                            </td>
                            <td className="py-4 px-6">
                                {item.totalItens}
                            </td>
                            <td className="py-4 px-6">
                                {item.totalVolumes}
                            </td>
                            <td className="py-4 px-6">
                                <a target="_blank" href={"https://soulog.movidesk.com/Ticket/Edit/" + item.ticketMovidesk}>
                                    {item.ticketMovidesk}
                                </a>
                                
                            </td>
                            <td className="py-4 px-6">
                                {getStatusEntrada(item)}
                            </td>
                            <td className="py-4 px-6">
                                {getAtrasoEntrada(item)}
                            </td>
                            <td className="flex flex-row py-6 px-8 cursor-pointer">
                                <img className="px-2" src={FileText} alt="Eye Icon" 
                                    onClick={() => handleGeraPdfClick(item.xml)}
                                    hidden={item.status == 8}
                                />

                                <img src={Eye} alt="Eye Icon" 
                                    onClick={() => props.handleRessalvaClick(item)}
                                    hidden={item.status != 9}
                                />
                                
                            </td>
                        </tr>
                    ))} 
                </tbody>
            </table>
        </div>
    )
}