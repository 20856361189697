import React, { useState } from 'react'
import UploadCloud from "../../../assets/svg/XMLModal/upload-cloud.svg";
import FileText from "../../../assets/svg/file-text.svg"
import X from "../../../assets/svg/x.svg"
export default function Archive({ setXmlData, setError }) {
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('')

    const onFileDrop = (e) => {
        if(e.target.files.length > 0) {
            const newFile = e.target.files[0]
            if (newFile) {
                setFile(newFile)

                let formData = new FormData();
                formData.append("files", newFile);
                setXmlData(formData);

                let nome = newFile.name;
                let resumo = '';
                if (nome.length > 30) {
                    resumo = nome.slice(0, 15);
                    resumo += '...' + nome.slice((nome.length - 15), nome.length);
                    setFileName(resumo);
                } else {
                    setFileName(nome);
                }
            }
        }
    }

    const handleDeleteFile = () => {
        setError(false);
        setFile(null);
        setXmlData(null);
        localStorage.setItem("@Xml:File", null);
    }

    return (
        <>
            <div className="flex flex-col justify-center px-8 py-4">
                <h3 className="font-bold text-lg">Carregue seu arquivo XML</h3>
            </div>
            { !file &&
                <div className="flex flex-col justify-center ">
                    <>
                        <div className="relative flex flex-col justify-center items-center border-dashed border-2 border-gray-500 bg-gray-500 bg-opacity-10 px-3 h-48 my-3">
                            <input type="file" accept="application/xml" className="absolute w-full h-full cursor-pointer top-0 left-0 opacity-0" value="" onChange={onFileDrop} />

                            <img src={UploadCloud} alt="Upload Cloud Icon" className="my-2 w-20" />

                            <p className="font-semibold text-md">Arraste e solte seu arquivo aqui</p>
                        </div>

                        <div className="relative flex py-3 justify-center items-center">
                            <div className="flex w-1/4 border-t border-gray-400"></div>
                            <span className="flex-shrink mx-4 font-semibold text-gray-500">ou</span>
                            <div className="flex w-1/4 border-t border-gray-400"></div>
                        </div>

                        <div className="flex flex-col justify-center items-center my-3">
                            <button className="relative border-[2px] border-soulog-900 text-soulog-900 text-sm font-semibold rounded-md px-4 py-2">
                                <input type="file" accept="application/xml" className="absolute w-full h-full cursor-pointer top-0 left-0 opacity-0" value="" onChange={onFileDrop} />
                                Selecionar um arquivo na sua máquina
                            </button>
                        </div>
                    </>
                </div>
                }

                {file && 
                    <div className="flex flex-col justify-center items-center px-8 py-4">
                        <>
                            <div className="relative flex flex-row justify-start ring-offset-2 ring-1 ring-gray-500 rounded-sm  px-3 h-14 w-96 my-3">
                            
                                <img src={FileText} alt="File Icon" className="my-2 w-6"/>
                                <p className="font-bold text-md my-3 ml-1">{fileName}</p>
                                <button className="absolute top-0 right-0 inline-block align-middle" onClick={handleDeleteFile}>
                                    <img src={X} alt="Close Icon" className="my-2 mr-2 w-4 rounded-full border-black border-solid border-2 "/>
                                </button>
                            </div>
                        </>
                    </div>
                }
        </>
    )
}
