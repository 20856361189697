import Alert from "../../assets/svg/menu/alert-triangle-red.svg"

export default function FilterBox({filter, onClick, passFilterStatus, filterStatus, checkFilter, error}) {

    const handleClick = () => {
        onClick(prevState => !prevState);

        passFilterStatus(filter.status);
    }

    const disableFilter = () => {
        if(filterStatus == filter.status && !filter.ressalva) {
            return false;
        }
         
        return checkFilter;
    }

    return (
        <>
        
        {!error &&
            <div className="flex flex-row justify-between items-center bg-white drop-shadow-xl rounded-md w-full px-3 py-3 my-3 lg:m-1 text-sm">
                <div className="flex">
                    <input type="checkbox" className="accent-emerald-600 text-blue-600 border-gray-200 rounded focus:ring-blue-500 mr-2" 
                    onClick={() => handleClick()} disabled={filterStatus == filter.status ? false : checkFilter}/>
                    
                    <h6>{filter.nome}</h6>
                </div>

                <span className="font-bold">{filter.quantidade}</span>
            </div>
        }

        {error &&
            <div className="flex flex-row justify-between items-center bg-red-50 drop-shadow-xl rounded-md w-full px-3 py-3 my-3 lg:m-1 text-sm">
                <div className="flex">
                    <input type="checkbox" className="accent-emerald-600 text-blue-600 border-gray-200 rounded focus:ring-blue-500 mr-2" 
                    onClick={() => handleClick()} disabled={filterStatus == filter.status ? false : checkFilter}/>
                    
                    <h6 className="text-red-900">{filter.nome}</h6>
                </div>

                <img className="px-3" src={Alert}/>
                <span className="font-bold">{filter.quantidade}</span>
            </div>
        }

        </>
    )
}