import { useState } from "react";
import Stepper from "./Stepper.component";
import StepperControl from "./StepperControl.component";
import Archive from "./steps/Archive.component";
import ChooseShipment from "./steps/ChooseShipment.component";
import ConfirmData from "./steps/ConfirmData.component";

export default function XmlModal({ setOpenModal }) {
    const [currentStep, setCurrentStep] = useState(1);

    const steps = [
        {title: "Carregar arquivo XML", desc: "Faça o upload do seu arquivo"}, 
        {title: "Confirmar dados", desc: "Confirme as informações"},
        {title: "Escolher envio", desc: "Confirme o envio"},
    ]

    const displayStep = (step) => {
        switch (step) {
            case 1:
                return <Archive />
            case 2:
                return <ConfirmData />
            case 3:
                return <ChooseShipment />
            default:
        }
    }

    const handleClick = (direction) => {
        let newStep = currentStep;

        direction === "next" ? newStep++ : newStep--

        newStep > 0 && newStep <= steps.length && setCurrentStep(newStep)
    }

    return (
        <div className="flex flex-col justify-center w-[85%] xl:w-[70%] mx-auto rounded-2xl px-5 bg-white drop-shadow-2xl">
            {/* Close button */}
            <span
                className="absolute -top-4 -right-4 bg-white drop-shadow-xl px-3 py-2.5 rounded-md text-soulog-900 font-bold cursor-pointer"
                onClick={() => setOpenModal()}
            >
                X
            </span>

            {/* Stepper */}
            <div className="xl:mt-5 w-full">
                <Stepper steps={steps} currentStep={currentStep} />
            </div>

            <div>
                {displayStep(currentStep)}
            </div>

            {/* Navigation Controls */}
            <StepperControl
                handleClick={handleClick}
                currentStep={currentStep}
                steps={steps}
                setOpenModal={setOpenModal}
            />
        </div>
    )
}