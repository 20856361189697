import { useState, useEffect } from "react";
import { Box, ShoppingBag, ShoppingCart } from "react-feather";
import { Link } from "react-router-dom";
import { useApi } from "../../hooks/useApi";
import Estoque from "../../view/Estoque";

export default function TableProdutos({searchFilter}) {
    const [tableItems, setTableItems] = useState([]);
    const [details, setDetails] = useState(false);

    const [tableItemsFilter, setTableItemsFilter] = useState([]);

    const filterItems = () => {

        let itens = [...tableItems];
        if(searchFilter.length <= 0) return setTableItemsFilter(itens);

        return setTableItemsFilter(itens.filter(x => x.produto?.descricao?.toUpperCase().includes(searchFilter.toUpperCase()) || 
                                                     x.produto?.codigo?.toUpperCase().includes(searchFilter.toUpperCase()) ||
                                                     x.produto?.gtin?.toUpperCase().includes(searchFilter.toUpperCase())));
    };


    const api = useApi();

    const getData = async ()=> {
        try {
          const response = await api.getProdutosClientes();
          
          if(response.status == 200) {
            if(response.data != null) {
                setTableItems(response.data);
                setTableItemsFilter(tableItems);
            }
          }
        } catch (error) {
          console.log(error);
        }
    
    };

      
    useEffect(() => {
        if(tableItems.length <= 0)
            getData();        
    }, []);

    useEffect(() => {
        filterItems();
    }, [searchFilter, tableItems]);

    return (
        <div className="overflow-x-auto mb-8">
            <table className="w-full text-sm text-left">
                <thead className="text-xs text-white uppercase bg-soulog-900">
                    <tr>
                        <th scope="col" className="py-3 px-6">

                        </th>
                        <th scope="col" className="py-3 px-6">
                            Descrição
                        </th>
                        <th scope="col" className="py-3 px-6">
                            SKU
                        </th>
                        <th scope="col" className="py-3 px-6">
                            EAN
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Estoque
                        </th>
                        <th scope="col" className="py-3 px-6">
                            
                        </th>
                    </tr>
                </thead>
                <tbody>
                {tableItemsFilter.map((item, index) => ( 

                    <tr key={index} className="bg-white border-b-2 border-gray-200">
                        <td className="py-4 px-6">
                        </td>
                        <td className="py-4 px-6 uppercase">
                            {item.produto.descricao}
                        </td>
                        <td className="py-4 px-6">
                            {item.produto.codigo}
                        </td>
                        <td className="py-4 px-6">
                            {item.produto.gtin}
                        </td>
                        <td className="py-4 px-6">
                            {item.estoque}
                        </td>
                        <td className="flex py-8 px-6 gap-x-3 items-center">
                            <Link to={`/estoque/${item.produto.id ?? 0}`}>
                                <Box/>
                            </Link>
                        </td>
                    </tr>
                ))}
                    
                </tbody>
            </table>
        </div>
    )
}