export default function Items({ quantity, title, subtitle, icon, alt, error, visible }) {
    return (
        (visible && 
            <div className="flex flex-row my-3">
                {error &&
                    <div className="bg-red-100 w-[55px] h-[55px] rounded-full">
                        <figure className="flex justify-center items-center h-full">
                            <img src={icon} alt={alt}/>
                        </figure>
                    </div>
                }

                {!error &&
                    <div className="bg-soulog-100 w-[55px] h-[55px] rounded-full">
                        <figure className="flex justify-center items-center h-full">
                            <img src={icon} alt={alt}/>
                        </figure>
                    </div>
                }

                <div className="flex flex-col ml-3 mr-2 justify-center text-md xl:text-sm">
                    <span className="font-bold">{quantity}</span>
                    <h3 className="font-bold">{title}</h3>
                    <h4 className="font-normal">{subtitle}</h4>
                </div>
            </div>
        )
    )
}