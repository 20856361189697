import { useState } from "react"

export default function ConfirmData() {
    const [formData, setFormData] = useState({
        cliente: '',
        cnpj: '',
        endereco: ''
    })

    return (
        <div className="flex flex-col justify-center px-8 py-4">
            <h3 className="font-bold text-lg">Confirme as informações abaixo</h3>

            <form className="flex flex-col my-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-3 w-full">
                    <div className="flex flex-col w-full">
                        <label htmlFor="cliente" className="font-semibold text-sm">Cliente</label>
                        <input
                            type="number"
                            id="cliente"
                            value={formData.nf}
                            onChange={(e) => setFormData({ ...formData, cliente: e.target.value })}
                            className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                            disabled={true}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <label htmlFor="cnpj" className="font-semibold text-sm">CNPJ</label>
                        <input
                            type="number"
                            id="cnpj"
                            value={formData.nf}
                            onChange={(e) => setFormData({ ...formData, cnpj: e.target.value })}
                            className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                            disabled={true}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <label htmlFor="endereco" className="font-semibold text-sm">Endereço</label>
                        <input
                            type="number"
                            id="endereco"
                            value={formData.nf}
                            onChange={(e) => setFormData({ ...formData, endereco: e.target.value })}
                            className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                            disabled={true}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}