/* eslint-disable no-lone-blocks */
import { useState } from 'react'
import { useApi } from '../../../hooks/useApi'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast, ToastContainer } from 'react-toastify';
import { base64MimeType, getTextFile } from "../../../helpers/functionsHelps"

const MySwal = withReactContent(Swal);

export default function StepperControl({ handleClick,
    currentStep, setOpenModal, error, setError, setErrorMsg,
    setObjPedido, infoAdicional, objPedido, setUpdatePedido, loading, setLoading, editPedido }) {

    const api = useApi();

    const handleCheckedAprovar = (e) => {
        setObjPedido({
            ...objPedido,
            aprovarPedido: e.target.checked
        });
    }

    const handleClickNext = async () => {
        try {
            setError(false);
            setLoading(true);
            console.log(objPedido);
            // eslint-disable-next-line default-case
            switch (currentStep) {
                case 1:
                    {
                        setUpdatePedido(false);
                        if (objPedido.idCanalVenda == null && objPedido?.pedidoInterno?.canalVenda == null) {
                            setError(true);
                            setErrorMsg("Favor selecionar o canal de venda.");
                            break;
                        }

                        if ((objPedido.isPedidoBling == false || objPedido.isPedidoBling == null) && objPedido.idContato == null) {
                            setError(true);
                            setErrorMsg("Favor selecionar o destinatário.");
                            break;
                        }

                        if (objPedido.idPedidoBling == null && objPedido.isPedidoBling == true) {
                            setError(true);
                            setErrorMsg("Favor sincronizar pedido Bling.");
                            break;
                        }

                        if (objPedido.isPedidoBling == false) {
                            let obj = { ...objPedido };

                            //obj.pedidoInterno = {};
                            obj.pedidoInterno.itensPedidoInterno = [];

                            setObjPedido(obj);
                        }

                        handleClick("next");
                    }
                    break;
                case 2:
                    {
                        try {
                            setLoading(true);

                            let obj = { ...objPedido };

                            for (const item of obj.pedidoInterno.itensPedidoInterno) {
                                const response = await api.getEstoqueProdutoId(item.produtos.id, 30);

                                if (response.status === 200) {
                                    if (response.data != null) {
                                        let retorno = response.data;

                                        item.estoque = retorno.estoque;
                                        console.log(response, retorno.qtdeSaidaUltimosDias, (parseFloat(retorno.qtdeSaidaUltimosDias) / 30) * 5)
                                        item.estoqueIdeal = retorno.qtdeSaidaUltimosDias > 0 ? (parseFloat((retorno.qtdeSaidaUltimosDias) / 30) * 5).toFixed(2) : 0;

                                        if (retorno.estoque < item.quantidade)
                                            item.isEstoque = false;
                                        else
                                            item.isEstoque = true;
                                    }
                                }
                            }
                            setObjPedido(obj);
                            handleClick("next");
                        } catch (error) {
                            toast.error('Erro ao validar estoque!', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                            console.log(error);
                        } finally {
                            setLoading(false);
                        }
                    }
                    break;
                case 3:
                    {
                        handleClick("next");
                    }
                    break;
                case 4:
                    {
                        // Desabilitado temporariamente.
                        // if (objPedido.aprovarPedido == true && objPedido.pedidoInterno.itensPedidoInterno.find(x => x.isEstoque == false)) {
                        //     toast.error('Favor verificar os itens que não possui estoque!', {
                        //         position: "top-right",
                        //         autoClose: 5000,
                        //         hideProgressBar: false,
                        //         closeOnClick: true,
                        //         pauseOnHover: true,
                        //         draggable: true,
                        //         progress: undefined,
                        //         theme: "colored",
                        //     });
                        //     return;
                        // }

                        if (objPedido.aprovarPedido == true) {
                            let retorno = await MySwal.fire({
                                icon: 'warning',
                                title: 'Seu pedido será gravado e enviado para análise.',
                                text: 'Tem certeza que deseja continuar? após essa etapa o pedido não poderá ser editado.',
                                confirmButtonColor: "#00948b",
                                showCancelButton: true,
                                cancelButtonText: 'Cancelar'
                            });

                            if (retorno.isConfirmed == false) return;
                        }

                        try {
                            delete objPedido.pedidoInterno?.pedido;

                            objPedido.idPedidoBling = objPedido.idPedidoBling?.toString();

                            await api.gravaPedidoFullB2B(objPedido);

                            toast.success(`Pedido Bling ${objPedido.pedidoBling} adicionado com sucesso.`, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });

                            setOpenModal(false);
                            setUpdatePedido(true);

                        } catch (error) {
                            console.log(error);

                            const match = error.response.data.match(/\[B2B\] [^\r\n]+/);
                            if (match) {
                                const result = match[0];
                                setErrorMsg(result);
                            } else {
                                console.log(error.data);
                                setErrorMsg('Não foi possivel salvar pedido, entre em contato com administrador.');
                            }

                            setError(true);
                        } finally {
                            setLoading(false);
                        }

                    }
                    break;
            }
        }
        catch (err) {
            console.log(err);
            setError(true);
            setErrorMsg("Não foi possível continuar, entre em contato com administrador.");
        }
        finally {
            setLoading(false);
        }

    };

    return (
        <div className="container flex justify-between mt-3 mb-8">
            <button
                className={`bg-white border-2 border-soulog-900 text-soulog-900 text-sm 
                font-semibold rounded-md px-5 py-2 ${(currentStep === 1 || (currentStep === 2 && editPedido.edit === true)) ? "opacity-50 cursor-not-allowed" : null}`}
                disabled={(currentStep === 2 && editPedido.edit === true)}
                onClick={() => handleClick()}
            >
                Voltar
            </button>

            <div className="flex items-right mt-2">
                {currentStep === 4 ? (
                    <>
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 mt-2"
                            onChange={(e) => handleCheckedAprovar(e)}
                        />
                        <label className="ml-2 mt-2 mr-5">Aprovar pedido e enviar para análise?</label>
                    </>
                ) : null}

                <button
                    className="bg-soulog-900 border-2 border-soulog-900 text-white text-sm font-semibold rounded-md px-5 py-2"
                    onClick={handleClickNext}
                    disabled={loading}
                >
                    {loading &&
                        <svg role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                        </svg>
                    }

                    {currentStep === 4 ? "Gravar pedido" : "Avançar"}

                </button>
            </div>

        </div>
    )
}
