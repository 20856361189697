import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { AuthContext } from "./AuthContext";
import { Navigate } from "react-router-dom";

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const api = useApi();

    useEffect(() => {
        validaToken();
    }, []);

    const validaToken = async () => {
        const storageToken = localStorage.getItem("@Auth:token");

        if(!storageToken) return logout();

        const logado = await logadoCliente(storageToken);
        if(logado.status == 200) {
            setUser(logado.data);
            localStorage.setItem("@Auth:nome", logado.data.nome);
            localStorage.setItem("@Auth:email", logado.data.email);
            localStorage.setItem("@Auth:tipo", logado.data.tipo);
            localStorage.setItem("@Cliente:nome", logado.data.cliente.nome);
            localStorage.setItem("@Cliente:cnpj", logado.data.cliente.cpfCnpj);
            localStorage.setItem("@Cliente:id", logado.data.cliente.id);
            localStorage.setItem("@Cliente:permissoes", JSON.stringify(logado.data.cliente.permissoes ?? {}));
            return true;
        } else {
            logout();
            return false;
        }
    }

    const loginCliente = async (email, senha) => {
        const response = await api.loginCliente(email, senha);
        if (response.status == 200) {
            
            const logado = await logadoCliente(response.data);

            if(logado.status == 200) {
                setUser(logado.data);
                localStorage.setItem("@Auth:nome", logado.data.nome);
                localStorage.setItem("@Auth:email", logado.data.email);
                localStorage.setItem("@Auth:tipo", logado.data.tipo);
                localStorage.setItem("@Cliente:nome", logado.data.cliente.nome);
                localStorage.setItem("@Cliente:cnpj", logado.data.cliente.cpfCnpj);
                localStorage.setItem("@Cliente:id", logado.data.cliente.id);
                localStorage.setItem("@Cliente:estoqueIdeal", logado.data.cliente.notificarEstoqueIdeal);
                return true;
            }
           return false;
        }
        return false;
    }

    const logadoCliente = async (token) => {
        return await api.logadoCliente(token);
    }

    const logout = () => {
        localStorage.clear();
        setUser(null);
        <Navigate to="/login" />;
        return false;
      };

    return (
        <AuthContext.Provider value={{ user, loginCliente, logadoCliente, validaToken, logout }}>
            {children}
        </AuthContext.Provider>
    );
}