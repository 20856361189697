import axios from 'axios';

const api = axios.create({
    baseURL: "https://apiwms.soulog.com.br"
    //baseURL: "https://localhost:7131"
});

export const useApi = () => ({
    loginCliente: async (email, senha) => {
        const response = await api.post('/UsuarioCliente/Login?email=' + email + '&senha=' + senha);
        if (response.status == 200) {
            localStorage.setItem("@Auth:token", response.data);
            api.defaults.headers.common["Authorization"] = `Bearer ${response.data}`;
        }
        return response;
    },

    logadoCliente: async (token) => {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await api.get('/UsuarioCliente/Logado');
        return response;
    },

    buscarEntradas: async () => {
        const response = await api.get('Portal/Entrada/BuscarEntradas');
        return response;
    },

    geraDanfe: async (xml) => {
        const response = await api.post('Portal/Entrada/GerarDanfe', xml);
        return response;
    },

    uploadXml: async (fileXml) => {
        const response = await api.post('Portal/Entrada/XmlUpload', fileXml, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    },

    getProduto: async (codigoProduto) => {
        const response = await api.get('Portal/Entrada/GetProduto?codigoProduto=' + codigoProduto);
        return response;
    },

    validaBling: async (entrada) => {
        const response = await api.post('Portal/Entrada/ValidaBling', entrada);
        return response;
    },

    gravarEntrada: async (entrada) => {
        const response = await api.post('Portal/Entrada/GravaEntrada', entrada);
        return response;
    },

    getPedidosSeparacao: async () => {
        const response = await api.get('Portal/Pedido/BuscaPedidosSeparacaoClienteId?clienteId=' + localStorage.getItem("@Cliente:id"));
        return response;
    },

    getPedidosSeparacaoDia: async () => {
        const response = await api.get('Portal/Pedido/BuscaPedidosSeparacaoDiaClienteId?clienteId=' + localStorage.getItem("@Cliente:id"));
        return response;
    },

    gravarEtiquetaEnvio: async (entrada) => {
        const response = await api.post('Portal/Pedido/GravarEtiquetaEnvio', entrada);
        return response;
    },

    getProdutosClientes: async () => {
        const response = await api.get('Estoque/ListaClienteProdutoEstoque?clienteId=' + localStorage.getItem("@Cliente:id") + '&descricao=&sku=&ean=');
        return response;
    },

    getEstoqueProdutoId: async (produtoId, quantosDiasAtras = 0) => {
        const response = await api.get('Estoque/ListaEstoqueProdutoById?produtoId=' + produtoId + '&quantosDiasAtras=' + quantosDiasAtras);
        return response;
    },

    getXmlDanfeEntrada: async (entradaId) => {
        const response = await api.get('Estoque/XmlDanfeEntrada?entradaId=' + entradaId);
        return response;
    },

    getXmlDanfeSaida: async (chaveNF) => {
        const response = await api.get('Estoque/XmlDanfeSaida?chaveNF=' + chaveNF);
        return response;
    },

    gerarDanfe: async (xml) => {
        const response = await api.post('Operador/Recebimento/GerarDanfe', xml, {

            "headers": {
                'Content-Type': 'text/plain'
            },

        });
        return response;
    },
    getStatusPedidosByClienteId: async () => {
        const response = await api.get('portal/Pedido/ListaStatusPedidosByClienteId?clienteId=' + localStorage.getItem("@Cliente:id"));
        return response;
    },
    getListaCanalVenda: async () => {
        const response = await api.get('B2B/ListaCanalVenda?clienteId=' + localStorage.getItem("@Cliente:id"));
        return response;
    },
    getListaContatos: async () => {
        const response = await api.get('B2B/ListaContatos?clienteId=' + localStorage.getItem("@Cliente:id"));
        return response;
    },
    atualizaContatos: async () => {
        const response = await api.post('B2B/AtualizaContatos?clienteId=' + localStorage.getItem("@Cliente:id"), null);
        return response;
    },
    sincronizarPedido: async (numeroPedido) => {
        const response = await api.post('B2B/SincronizarPedidoBling?clienteId=' + localStorage.getItem("@Cliente:id") + '&numeroPedido=' + numeroPedido, null);
        return response;
    },
    buscaProduto: async (codigo) => {
        const response = await api.get('B2B/buscaProduto?clienteId=' + localStorage.getItem("@Cliente:id") + '&codigo=' + codigo);
        return response;
    },
    gravaPedidoFullB2B: async (pedido) => {
        const response = await api.post('B2B/GravaPedidoFullB2B', pedido);
        return response;
    },
    listaPedidosB2B: async () => {
        const response = await api.get('B2B/ListaPedidosB2B?clienteId=' + localStorage.getItem("@Cliente:id"));
        return response;
    },
    gravaListaPedido: async (pedido) => {
        const response = await api.post('B2B/GravarListaPedido', pedido);
        return response;
    },
    gravaAnexosDocumentacao: async (pedidoInternoId, documentacao) => {
        const response = await api.post('B2B/GravaAnexosDocumentacao?pedidoInternoId=' + pedidoInternoId, documentacao);
        return response;
    },
});