import React, { useState } from 'react'

export default function HistoricoTransicao() {
    const [tableItems, setTableItems] = useState([]);

    return (
        <div className="flex flex-col h-52">
                <h3 className="font-bold text-lg">Histórico de transição entre status</h3>

                <div className="overflow-x-auto overflow-y-auto h-auto mb-8">
                    <table className="w-full text-sm text-left mt-3">
                        <thead className="text-xs text-white uppercase bg-soulog-900">
                            <tr>
                                <th scope="col" className="py-3 px-12">
                                    Data
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Status Anterior
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Status
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr className="bg-white border-b-2 border-gray-200">
                                <td className="py-3 px-12">
                                    22/12/2022
                                </td>
                                <td className="py-3 px-6 uppercase">
                                    Aguardando Etiqueta
                                </td>
                                <td className="py-3 px-6">
                                    Novo
                                </td>
                            </tr>

                            <tr className="bg-white border-b-2 border-gray-200">
                                <td className="py-3 px-12">
                                    22/12/2022
                                </td>
                                <td className="py-3 px-6 uppercase">
                                    Aguardando Etiqueta
                                </td>
                                <td className="py-3 px-6">
                                    Novo
                                </td>
                            </tr>

                            {/* {tableItems.map((item) => (
                                <tr className="bg-white border-b-2 border-gray-200" key={item.id}>
                                    <td className="py-3 px-12">
                                        {item.data}
                                    </td>
                                    <td className="py-3 px-6 uppercase">
                                        {item.status}
                                    </td>
                                    <td className="py-3 px-6">
                                        {item.statusNovo}
                                    </td>
                                </tr>
                            ))} */}
                        </tbody>
                    </table>
                </div>
            </div>
    )
}
