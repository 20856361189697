import axios from 'axios';

const api = axios.create({
    baseURL: "https://newapiwms.soulog.com.br/api/"
    //baseURL: "https://localhost:7118/api/"
});

export const useNewApi = () => ({
    getTokenBlingValido: async () => {
        const response = await api.get('BlingV3/tokenvalido/' + localStorage.getItem("@Cliente:id"));
        return response;
    }
});