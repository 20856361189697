import { useState } from 'react';
import TableConferirProdutos from '../../Table/TableConferirProdutos.component';
import Form from '../Form.component';

export default function CheckProducts({ setObjXml, objXml }) {
    const [checked, setChecked] = useState(false);

    const checkItem = () => {
        setChecked(prevState => !prevState)
    }

    return (
        <div className="px-8 py-4">
            <h3 className="font-bold text-lg">Favor realizar a validação final dos itens</h3>

            {/*checked ? <Form /> : null*/}

            <TableConferirProdutos setChecked={checkItem} setObjXml={setObjXml} objXml={objXml} />
        </div>
    )
}
