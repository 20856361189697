import { useState, useEffect } from "react";
import FilterBox from "../components/Entrada/FilterBox.component";
import TableEntrada from "../components/Entrada/TableEntrada.component";
import ToggleButton from "../components/Entrada/ToggleButton.component";
import Layout from "../components/Layout/Layout.component";
import XmlModal from "../components/XmlModal/XmlModal.component";
import RessalvaModal from "../components/RessalvaModal/RessalvaModal.component";
import { Navigate } from "react-router-dom";
import 
{
    getLengthEntradasArmazenadas, 
    getLengthEntradasArmazenadasRessalva, 
    getLengthEntradasConferencia, 
    getLengthEntradasPendencias, 
    getLengthEntradasRecebidas, 
    getLengthEntradasRegistradas } from "../helpers/functionsHelps";

export default function Entrada() {

    const [openModal, setOpenModal] = useState(false);
    const [toggle, setToggle] = useState(true);
    const [tableItems, setTableItems] = useState([]);
    const [updateEntrada, setUpdateEntrada] = useState(false);
    
    const [filterTodas, setFilterTodas] = useState({});
    const [filterRegistradas, setFilterRegistradas] = useState({});
    const [filterRecebidas, setFilterRecebidas] = useState({});
    const [filterConferencia, setFilterConferencia] = useState({});
    const [filterArmazenadas, setFilterArmazenadas] = useState({});
    const [filterRessalva, setFilterRessalva] = useState({});
    const [filterPendencias, setfilterPendencias] = useState({});

    const [filter, setFilter] = useState(false);
    const [filterStatus, setFilterStatus] = useState(null);
    const [filterStatusRessalva, setFilterStatusRessalva] = useState(false);

    const [openRessalvaModal, setRessalvaModal] = useState(false);
    const [ressalvaItem, setRessalvaItem] = useState(null);

    

    useEffect(() => {
        
        setFilterTodas({
            nome: "Todas",
            quantidade: tableItems.length,
            status: 0,
            ressalva: false
        });

        setFilterRegistradas({
            nome: "Registradas",
            quantidade: getLengthEntradasRegistradas(tableItems),
            status: 1,
            ressalva: false
        });

        setFilterRecebidas({
            nome: "Recebidas",
            quantidade: getLengthEntradasRecebidas(tableItems),
            status: 2,
            ressalva: false
        });

        setFilterConferencia({
            nome: "Em Conferência",
            quantidade: getLengthEntradasConferencia(tableItems),
            status: 3,
            ressalva: false
        });

        setFilterArmazenadas({
            nome: "Armazenadas",
            quantidade: getLengthEntradasArmazenadas(tableItems),
            status: 5,
            ressalva: false
        });

        setFilterRessalva({
            nome: "Armazenado com ressalva",
            quantidade: getLengthEntradasArmazenadasRessalva(tableItems),
            status: 5,
            ressalva: true
        });

        setfilterPendencias({
            nome: "Pendências",
            quantidade: getLengthEntradasPendencias(tableItems),
            status: 8,
            ressalva: false
        });

      }, [toggle, tableItems]);

    
    const handleClick = () => {
        setOpenModal(prevState => !prevState);
        setUpdateEntrada(false);
    }

    
    const handleRessalvaClick = (item) => {
        setRessalvaModal(prevState => !prevState);
        setRessalvaItem(item ?? null);
    }

    if (JSON.parse(localStorage.getItem("@Cliente:permissoes")).entrada != null &&
        JSON.parse(localStorage.getItem("@Cliente:permissoes")).entrada == false) {
        return <Navigate to="/home" />;
    }

    return (
        <>
            <div className={`${openModal ? "fixed" : "hidden"} flex items-center bg-gray-800 bg-opacity-50 z-30 w-full h-full`}>
                {openModal ? <XmlModal setUpdateEntrada={setUpdateEntrada} setOpenModal={handleClick} setToggle={setToggle} toggle={toggle}/> : null}
            </div>

            <div className={`${openRessalvaModal ? "fixed" : "hidden"} flex items-center bg-gray-800 bg-opacity-50 z-30 w-full h-full`}>
                {openRessalvaModal ? <RessalvaModal setRessalvaModal={handleRessalvaClick} ressalvaItem={ressalvaItem} tableItems={tableItems}/> : null}
            </div>

            <Layout title="Entrada">
                <div className="bg-white drop-shadow-xl rounded-md w-full h-auto my-8 px-8 pt-3 pb-10">
                    <h3 className="font-bold text-lg">Lista de entradas</h3>

                    {/*<ToggleButton toggle={toggle} onClick={() => setToggle(!toggle)} />*/}

                    <button
                        className="absolute right-8 top-5 lg:right-12 lg:top-8 border-2 border-soulog-900 text-soulog-900 p-2 lg:px-4 lg:py-3 hover:bg-soulog-900 hover:text-white font-bold text-[12px] rounded-md transition-all duration-300"
                        onClick={handleClick}
                    >
                        Adicionar XML
                    </button>

                    <div className="flex flex-col xl:flex-row justify-between xl:justify-around mt-6">

                        <FilterBox filter={filterPendencias} 
                        onClick={() => setFilter(!filter)} 
                        passFilterStatus={setFilterStatus} 
                        filterStatus={filterStatus}
                        checkFilter={filter}
                        error={true} 
                        setFilterStatusRessalva={setFilterStatusRessalva}/>

                        <FilterBox filter={filterTodas} 
                        onClick={() => setFilter(!filter)} 
                        passFilterStatus={setFilterStatus} 
                        filterStatus={filterStatus}
                        checkFilter={filter} 
                        setFilterStatusRessalva={setFilterStatusRessalva}/>

                        <FilterBox filter={filterRegistradas} 
                        onClick={() => setFilter(!filter)} 
                        passFilterStatus={setFilterStatus} 
                        filterStatus={filterStatus}
                        checkFilter={filter}
                        setFilterStatusRessalva={setFilterStatusRessalva}/>

                        <FilterBox filter={filterRecebidas} 
                        onClick={() => setFilter(!filter)} 
                        passFilterStatus={setFilterStatus}
                        filterStatus={filterStatus} 
                        checkFilter={filter}
                        setFilterStatusRessalva={setFilterStatusRessalva}/>

                        <FilterBox filter={filterConferencia} 
                        onClick={() => setFilter(!filter)} 
                        passFilterStatus={setFilterStatus} 
                        filterStatus={filterStatus}
                        checkFilter={filter}
                        setFilterStatusRessalva={setFilterStatusRessalva}/>

                        <FilterBox filter={filterArmazenadas} 
                        onClick={() => setFilter(!filter)} 
                        passFilterStatus={setFilterStatus} 
                        filterStatus={filterStatus}
                        checkFilter={filter}
                        setFilterStatusRessalva={setFilterStatusRessalva}/>


                    </div>
                </div>
                
                <TableEntrada 
                toggle={toggle} 
                passTableItems={setTableItems} 
                filterStatus={filterStatus} 
                checkFilter={filter}
                updateEntrada={updateEntrada}
                handleRessalvaClick={handleRessalvaClick}
                openRessalvaModal={openRessalvaModal}
                filterStatusRessalva={filterStatusRessalva}
                />
            </Layout>
        </>
    )
}