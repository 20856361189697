import { useState } from "react";
import Layout from "../../components/Layout/Layout.component";
import InfoContainer from "../../components/Produtos/InfoContainer.component";
import FilterModal from "../../components/Produtos/FilterModal.component";
// import TableEstoque from "../components/Estoque/TableEstoque.component";
import { Filter, RefreshCcw } from "react-feather";
import TableProdutos from "../../components/Produtos/TableProdutos.component";
import { Navigate } from "react-router-dom";

export default function Produtos() {
   

    const [searchFilter, setSearchFilter] = useState('');

    const [openFilter, setOpenFilter] = useState(false);

    const openFilterModal = () => {
        setOpenFilter(prevState => !prevState)
    }

    if (JSON.parse(localStorage.getItem("@Cliente:permissoes")).produtos != null && 
    JSON.parse(localStorage.getItem("@Cliente:permissoes")).produtos == false) {
        return <Navigate to="/home" />;
    }

    return (
        <Layout title="Produtos">
            <main className="flex flex-col xl:flex-row mt-6 h-screen">
                <div className="flex flex-col drop-shadow-xl h-auto w-full">
                    <div className="h-auto bg-white rounded-t-md px-8 py-4">
                        <div className="flex flex-col md:flex-row gap-x-12 gap-y-3">
                            {/* Filtro 
                            <div className="flex flex-col">
                                <h3 className="font-bold text-sm mb-2">Filtros</h3>

                                <div
                                    className="flex items-center justify-center border-[1px] border-gray-300 w-10 h-10 rounded-md cursor-pointer"
                                    onClick={openFilterModal}
                                >
                                    <Filter/>
                                </div>

                                {openFilter && (
                                    <FilterModal openFilterModal={openFilterModal} />
                                )}
                            </div> */}

                            {/* Pesquisa por produto */}
                            <div className="relative flex flex-col">
                                <label htmlFor="pesquisaporproduto" className="font-bold text-sm mb-2">Pesquisa por produto</label>

                                <input
                                    type="text"
                                    id="pesquisaporproduto"
                                    className="border-[1px] border-gray-300 px-3 w-full lg:w-[600px] h-10 rounded-md text-xs"
                                    placeholder="Pesquise um produto"
                                    value={searchFilter}
                                    onChange={(e) => setSearchFilter(e.target.value)}
                                />

                                <span
                                    className="text-sm underline absolute right-0 -bottom-8 cursor-pointer"
                                    onClick={() => setSearchFilter("")}
                                >
                                    Limpar filtros
                                </span>
                            </div>
                        </div>

                         <div className="flex flex-col md:flex-row gap-x-7 gap-y-5 mt-5">
                            {/*<div className="flex items-center gap-x-2">
                                <input type="checkbox" />
                                <span className="text-sm">Selecionar todos</span>
                            </div>

                            <div className="flex items-center gap-x-2">
                                <RefreshCcw className="h-4"/>
                                <span className="text-sm">Atualizar dados</span>
                            </div>*/}
                        </div>
                    </div>

                    <TableProdutos 
                     searchFilter={searchFilter}
                    />
                </div>
            </main>
        </Layout>
    )
}