export const getStatusEntrada = (item) => {
    let statusId = item.status;

    if (statusId == 1) {
        return (
            <span className="bg-green-300 py-2 px-2 text-xs rounded-3xl">
                Registrada
            </span>
        )
    }

    if (statusId == 2) {
        return (
            <span className="bg-green-300 py-2 px-2 text-xs rounded-3xl">
                Recebida
            </span>
        )
    }

    if (statusId == 3) {
        return (
            <span className="bg-yellow-300 py-2 px-2 text-xs rounded-3xl">
                Em Conferência
            </span>
        )
    }

    if (statusId == 5 && !item.ressalva) {
        return (
            <span className="bg-green-300 py-2 px-2 text-xs rounded-3xl">
                Armazenada
            </span>
        )
    }

    if (statusId == 5 && item.ressalva == true) {
        return (
            <span className="bg-green-300 py-2 px-2 text-xs rounded-3xl">
                Armazenada com Ressalva
            </span>
        )
    }

    if (statusId == 8) {
        return (
            <span className="bg-red-300 py-2 px-2 text-xs rounded-3xl">
                Aguardando XML
            </span>
        )
    }

    if (statusId == 9) {
        return (
            <span className="bg-red-300 py-2 px-2 text-xs rounded-3xl">
                Conferido com Ressalva
            </span>
        )
    }

    return (
        <span className="bg-red-300 py-2 px-2 text-xs rounded-3xl">
            Não Identificado
        </span>
    )
}

export const getStatusPedidoInterno = (item) => {
    let statusId = item.status;

    if (statusId == 0) {
        return (
            <span className="bg-blue-300 py-2 px-2 text-xs rounded-3xl">
                Registrada
            </span>
        )
    }

    if (statusId == 1) {
        return (
            <span className="bg-green-300 py-2 px-2 text-xs rounded-3xl">
                Em Análise
            </span>
        )
    }

    if (statusId == 2 || statusId == 3) {
        return (
            <span className="bg-yellow-300 py-2 px-2 text-xs rounded-3xl">
                Em Separação
            </span>
        )
    }

    if (statusId == 4) {
        return (
            <span className="bg-orange-300 py-2 px-2 text-xs rounded-3xl">
                Documentação Despacho
            </span>
        )
    }

    if (statusId == 5) {
        return (
            <span className="bg-gray-300 py-2 px-2 text-xs rounded-3xl">
                Documentação Despacho Enviada
            </span>
        )
    }

    if (statusId == 6 || statusId == 7) {
        return (
            <span className="bg-purple-300 py-2 px-2 text-xs rounded-3xl">
                Aguardando Coleta
            </span>
        )
    }

    if (statusId == 9) {
        return (
            <span className="bg-green-400 py-2 px-2 text-xs rounded-3xl">
                Coletado
            </span>
        )
    }

    return (
        <span className="bg-red-300 py-2 px-2 text-xs rounded-3xl">
            Não Identificado
        </span>
    )
}

export const getRessalva = (ressalva) => {

    if (ressalva == 1) {
        return (
            <span className="bg-yellow-300 py-2 px-2 text-xs rounded-3xl">
                Itens a Mais
            </span>
        )
    }

    if (ressalva == 2) {
        return (
            <span className="bg-yellow-300 py-2 px-2 text-xs rounded-3xl">
                Itens a Menos
            </span>
        )
    }

    if (ressalva == 3) {
        return (
            <span className="bg-yellow-300 py-2 px-2 text-xs rounded-3xl">
                Avariados
            </span>
        )
    }

    if (ressalva == 4) {
        return (
            <span className="bg-yellow-300 py-2 px-2 rounded-3xl text-xs">
                Sem Identificação
            </span>
        )
    }
}

export const getStatusSeparacao = (status) => {

    if (status == 1 || status == 2 || status == 13) {
        return (
            <span className="bg-yellow-300 py-2 px-2 text-xs rounded-3xl">
                Aguardando distribuição
            </span>
        )
    }

    if (status == 4) {
        return (
            <span className="bg-red-300 py-2 px-2 text-xs rounded-3xl">
                Sem SKU
            </span>
        )
    }

    if (status == 5) {
        return (
            <span className="bg-red-300 py-2 px-2 text-xs rounded-3xl">
                Sem Logística
            </span>
        )
    }

    if (status >= 6 && status <= 8) {
        return (
            <span className="bg-blue-300 py-2 px-2 rounded-3xl text-xs">
                Em separação
            </span>
        )
    }

    if (status == 9 || status == 12) {
        return (
            <span className="bg-purple-300 py-2 px-2 rounded-3xl text-xs">
                Aguardando embalagem
            </span>
        )
    }

    if (status == 10) {
        return (
            <span className="bg-green-300 py-2 px-2 rounded-3xl text-xs">
                Aguardando coleta
            </span>
        )
    }

    if (status == 11) {
        return (
            <span className="bg-orange-300 py-2 px-2 rounded-3xl text-xs">
                Aguardando etiqueta
            </span>
        )
    }

    if (status == 14) {
        return (
            <span className="bg-yellow-200 py-2 px-2 text-xs rounded-3xl">
                Agendado
            </span>
        )
    }

    if (status == 98) {
        return (
            <span className="bg-red-300 py-2 px-2 rounded-3xl text-xs">
                Cancelado
            </span>
        )
    }

    if (status == 99) {
        return (
            <span className="bg-green-200 py-2 px-2 rounded-3xl text-xs">
                Coletado
            </span>
        )
    }
}

export const getAtrasoEntrada = (item) => {
    let segundos = item.slaConferencia;

    if (item.status == 5) {
        return (
            <span className="bg-green-300 py-2 px-2 text-xs rounded-3xl">
                Liberado para Venda
            </span>
        )
    }

    let tipo = 1;

    if (parseFloat(segundos) > parseFloat(115200)) tipo = 2;
    if (parseFloat(segundos) > parseFloat(172800)) tipo = 3;
    if (!segundos) tipo = 0;

    switch (tipo) {
        case 1:
            return (
                <span className="bg-green-300 py-2 px-2 text-xs rounded-3xl">
                    No Prazo
                </span>
            )
            break;
        case 2:
            return (
                <span className="bg-yellow-300 py-2 px-2 text-xs rounded-3xl">
                    Proximo Atrasar
                </span>
            )
            break;
        case 3:
            return (
                <span className="bg-red-300 py-2 px-2 text-xs rounded-3xl">
                    Atrasado
                </span>
            )
            break;
        default:
            return (
                <span className="bg-gray-300 py-2 px-2 text-xs rounded-3xl">
                    N/A
                </span>
            )
            break;
    }

}

export const getLengthStatusEntradas = (entradas, status) => {
    return entradas.filter(x => x.status == status).length;
}

export const getLengthEntradasRegistradas = (entradas) => {
    return getLengthStatusEntradas(entradas, 1);
}

export const getLengthEntradasRecebidas = (entradas) => {
    return getLengthStatusEntradas(entradas, 2);
}

export const getLengthEntradasConferencia = (entradas) => {
    return getLengthStatusEntradas(entradas, 3);
}

export const getLengthEntradasArmazenadas = (entradas) => {
    return entradas.filter(x => x.status == 5).length;
}

export const getLengthEntradasArmazenadasRessalva = (entradas) => {
    return entradas.filter(x => x.status == 5 && x.ressalva == true).length;
}

export const getLengthEntradasPendencias = (entradas) => {
    return entradas.filter(x => x.status == 8 || x.status == 9).length;
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const getTextFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const base64MimeType = (encoded) => {
    var result = null;

    if (typeof encoded !== 'string') {
        return result;
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
        result = mime[1];
    }

    return result;
}
