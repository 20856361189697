import React, { useState } from 'react'
import moment from 'moment'

export default function FillData({ objXml, setInfoAdicional }) {
    const [formData, setFormData] = useState({
        nf: objXml.entrada.numeroNF,
        nomeFornecedor: objXml.fornecedor.nome,
        idFornecedor: '',
        numVolumes: objXml.entrada.totalVolumes,
        dataPrevista: moment().format('YYYY-MM-DD'),
        qtdVolumes: ''
    });

    const handleChange = (e, item) => {
        
        if(item == "dataPrevista") setFormData({ ...formData, dataPrevista: moment(e.target.value).format('YYYY-MM-DD') });
        if(item == "qtdVolumes") setFormData({ ...formData, qtdVolumes: e.target.value });

        setInfoAdicional(formData);
    }

    return (
        <div className="px-8 py-4">
            <h3 className="font-bold text-lg">Preencha as informações abaixo</h3>

            <form className="flex flex-col my-4">
                <div className="flex flex-col xl:flex-row w-full">
                    <div className="flex flex-col w-full">
                        <label htmlFor="nf" className="font-semibold text-sm">N° NF</label>
                        <input
                            type="number"
                            id="nf"
                            value={formData.nf}
                            onChange={(e) => setFormData({ ...formData, nf: e.target.value })}
                            className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                            disabled={true}
                        />
                    </div>

                    <div className="flex flex-col ml-0 xl:ml-8 w-full">
                        <label htmlFor="nomeFornecedor" className="font-semibold text-sm">Nome do Fornecedor</label>
                        <input
                            type="text"
                            id="nomeFornecedor"
                            value={formData.nomeFornecedor}
                            onChange={(e) => setFormData({ ...formData, nomeFornecedor: e.target.value })}
                            className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                            disabled={true}
                        />
                    </div>

                    {/* <div className="flex flex-col ml-0 xl:ml-8 w-full">
                        <label htmlFor="idFornecedor" className="font-semibold text-sm">ID Fornecedor</label>
                        <input
                            type="text"
                            id="idFornecedor"
                            value={formData.idFornecedor}
                            onChange={(e) => setFormData({ ...formData, nomeFornecedor: e.target.value })}
                            className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                            disabled={true}
                        />
                    </div>
                    */}

                    <div className="flex flex-col ml-0 xl:ml-8 w-full">
                        <label htmlFor="numVolumes" className="font-semibold text-sm">N° de Volumes</label>
                        <input
                            type="text"
                            id="numVolumes"
                            value={formData.numVolumes}
                            onChange={(e) => setFormData({ ...formData, numVolumes: e.target.value })}
                            className="border-[1px] bg-gray-300 rounded-md my-1 p-2"
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="flex flex-col xl:flex-row w-full mt-4">
                    <div className="flex flex-col w-full xl:w-[23%]">
                        <label htmlFor="dataPrevista" className="font-semibold text-sm">Data prevista de entrega</label>
                        <input
                            type="date"
                            id="dataPrevista"
                            value={formData.dataPrevista}
                            onChange={(e) => handleChange(e, "dataPrevista")}
                            className="border-[1px] rounded-md my-1 p-2"
                        />
                    </div>

                    <div className="flex flex-col ml-0 xl:ml-8 w-full xl:w-[23%]">
                        <label htmlFor="qtdVolumes" className="font-semibold text-sm">Quantidade de volumes</label>
                        <input
                            type="text"
                            id="qtdVolumes"
                            value={formData.qtdVolumes}
                            onChange={(e) => handleChange(e, "qtdVolumes")}
                            onKeyUp={(e) => handleChange(e, "qtdVolumes")}
                            className="border-[1px] rounded-md my-1 p-2"
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
