import React, { useState } from 'react'

export default function ItensDoPedido({ pedidoItem, setPedidoItem }) {

    return (
        <div className="flex flex-col mt-4 h-full">
                <h3 className="font-bold text-lg">Confirme os Itens do pedido</h3>

                <div className="overflow-x-auto overflow-y-auto h-auto mb-8 ">
                    <table className="w-full text-sm text-left mt-3">
                        <thead className="text-xs text-white uppercase bg-soulog-900">
                            <tr>
                                <th scope="col" className="py-3 px-12">
                                    Código
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Descrição
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    EAN
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    SKU
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Etiqueta
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Quantidade
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Quantidade Sistema
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {pedidoItem.listaPedidoPDFML.map((item, index) => (
                                <tr key={index} className={`border-b-2 border-gray-200 ${(item.validadoQuantidade && item.validadoSKU)  ? 'bg-green-100' : 'bg-red-100'}`}>
                                    <td className="py-3 px-12">
                                        {item.codigo}
                                    </td>
                                    <td className="py-3 px-6 uppercase">
                                        {item.descricao}
                                    </td>
                                    <td className="py-3 px-6">
                                        {item.ean}
                                    </td>
                                    <td className="py-3 px-6">
                                        {item.sku}
                                    </td>
                                    <td className="py-3 px-6">
                                        {item.etiqueta}
                                    </td>
                                    <td className="py-3 px-6">
                                        {item.quantidade}
                                    </td>
                                    <td className="py-3 px-6">
                                        {item.quantidadeSistema}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
    )
}
