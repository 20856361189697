import { useState } from "react";
import Stepper from "./Stepper.component";
import StepperControl from "./StepperControl.component";
import Archive from "./steps/Archive.component";
import FillData from "./steps/FillData.component";
import CheckProducts from "./steps/CheckProducts.component";
import ValidateERP from "./steps/ValidateERP.component";
import FileText from "../../assets/svg/file-text.svg"
import X from "../../assets/svg/x.svg"
import { getBase64 } from "../../helpers/functionsHelps";

export default function XmlModal({ setOpenModal, setUpdateEntrada }) {
    const [currentStep, setCurrentStep] = useState(1);
    const [xmlData, setXmlData] = useState(null);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [objXml, setObjXml] = useState(null);
    const [checkCCe, setCheckCCe] = useState(false);

    const [infoAdicional, setInfoAdicional] = useState({});

    const [file, setFile] = useState('')
    const [fileName, setFileName] = useState('')

    const onFileDrop = async (e) => {
        if(e.target.files.length > 0) {
            const newFile = e.target.files[0]
            if (newFile) {
                setCheckCCe(false);
                setError(false);

                let base = await getBase64(newFile)
                setFile(base);

                let nome = newFile.name;
                let resumo = '';
                if (nome.length > 30) {
                    resumo = nome.slice(0, 12);
                    resumo += '...' + nome.slice((nome.length - 12), nome.length);
                    setFileName(resumo);
                } else {
                    setFileName(nome);
                }
            }
        }
    }

    const handleDeleteFile = () => {
        setCheckCCe(true);
        setError(true);
        setFile('');
    }

    const steps = [
        {title: "Carregar arquivo XML", desc: "Faça o upload do seu arquivo"}, 
        {title: "Preencher dados", desc: "Preencha as informações"},
        {title: "Conferir produtos", desc: "Confirme os produtos"},
        {title: "Validar com ERP", desc: "Faça a validação final"}
    ]

    const displayStep = (step) => {
        switch (step) {
            case 1:
                return <Archive setXmlData={setXmlData} setError={setError}/>
            case 2:
                return <FillData objXml={objXml} setInfoAdicional={setInfoAdicional}/>
            case 3:
                return <CheckProducts setObjXml={setObjXml} objXml={objXml}/>
            case 4:
                return <ValidateERP setObjXml={setObjXml} objXml={objXml}/>
            default:
        }
    }

    const handleClick = (direction) => {
        let newStep = currentStep;
        setError(false);
        setCheckCCe(false);
        direction === "next" ? newStep++ : newStep--

        newStep > 0 && newStep <= steps.length && setCurrentStep(newStep)
    }

    return (
        <div className="flex flex-col h-[95%] justify-center w-[95%] xl:w-[95] mx-auto rounded-2xl px-5 bg-white drop-shadow-2xl">
            {/* Close button */}
            <span
                className="absolute -top-4 -right-4 bg-white drop-shadow-xl px-3 py-2.5 rounded-md text-soulog-900 font-bold cursor-pointer"
                onClick={() => setOpenModal()}
            >
                X
            </span>

            {/* Stepper */}
            <div className="xl:mt-5 w-full">
                <Stepper steps={steps} currentStep={currentStep} />
            </div>

            <div className="overflow-y-auto">
                {displayStep(currentStep)}
            </div>

            {error &&
                <div className="xl:mt-5 w-full">
                    <span className="flex flex-col justify-center text-center bg-red-300 py-2 px-3 rounded-2">
                        {errorMsg}
                    </span>
                </div>
            }

            {checkCCe && 
                <div className="flex flex-col justify-center items-center my-3">
                    <button className="relative border-[2px] border-soulog-900 text-soulog-900 text-sm font-semibold rounded-md px-4 py-2">
                        <input type="file" accept="application/pdf" className="absolute w-full h-full cursor-pointer top-0 left-0 opacity-0" value="" onChange={onFileDrop} />
                        Selecionar o PDF da carta de correção
                    </button>
                </div>
            }

            {file && 
                <div className="flex flex-col justify-center items-center px-8 py-4">
                    <>
                        <div className="relative flex flex-row justify-start ring-offset-2 ring-1 ring-gray-500 rounded-sm  px-3 h-14 w-96 my-3">
                        
                            <img src={FileText} alt="File Icon" className="my-2 w-6"/>
                            <p className="font-bold text-md my-3 ml-1">{fileName}</p>
                            <button className="absolute top-0 right-0 inline-block align-middle" onClick={handleDeleteFile}>
                                <img src={X} alt="Close Icon" className="my-2 mr-2 w-4 rounded-full border-black border-solid border-2 "/>
                            </button>
                        </div>
                    </>
                </div>
            }

            {/* Navigation Controls */}
            <StepperControl
                handleClick={handleClick}
                currentStep={currentStep}
                steps={steps}
                setOpenModal={setOpenModal}
                xmlData={xmlData}
                error={error}
                setError={setError} 
                setErrorMsg={setErrorMsg}
                setObjXml={setObjXml}
                infoAdicional={infoAdicional}
                objXml={objXml}
                setUpdateEntrada={setUpdateEntrada}
                setCheckCCe={setCheckCCe}
                file={file}
                setFile={setFile}
            />
        </div>
    )
}