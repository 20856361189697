import {React, useState} from 'react'
import ItensDoPedido from './ItensDoPedido.component'
import { useEffect } from 'react';

export default function DetailsPDFModal({ openModal, pedidoItem, setPedidoItem }) {
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const validacao = pedidoItem.listaPedidoPDFML.find(x => x.validadoQuantidade == false || x.validadoSKU == false);

        if(validacao != null && error == false) {
            setErrorMsg('Produtos ou quantidades estão divergente com o pedido no sistema.');
            setError(true);
        }
    }, [])

    return (
        <div className="flex flex-col h-[95%] justify-center w-[95%] xl:w-[95] mx-auto rounded-2xl px-5 bg-white drop-shadow-2xl">
            <span
                className="absolute -top-4 -right-4 bg-white drop-shadow-xl px-3 py-2.5 rounded-md text-soulog-900 font-bold cursor-pointer"
                onClick={() => openModal()}
            >
                X
            </span>

            <div className="w-full h-[75%]">
                <ItensDoPedido pedidoItem={pedidoItem} setPedidoItem={setPedidoItem} />
            </div>

            {error &&
                <div className="xl:mt-5 w-full">
                    <span className="flex flex-col justify-center text-center bg-red-300 py-2 px-3 rounded-2">
                        {errorMsg}
                    </span>
                </div>
            }


            <div className="flex justify-center items-center mt-2">
                <button
                    className="border-2 border-soulog-900 text-soulog-900 hover:bg-soulog-900 hover:text-white transition-all duration-300 ease-in-out font-semibold p-2 w-56 rounded-md"
                    onClick={() => openModal()}
                >
                    Fechar
                </button>

                <button
                    className="border-2 border-soulog-900 text-soulog-900 hover:bg-soulog-900 hover:text-white transition-all duration-300 ease-in-out font-semibold p-2 w-56 rounded-md"
                    onClick={() => openModal()}
                >
                    Aceitar
                </button>
            </div>
        </div>
    )
}
