import { useState } from "react";
import { useParams } from 'react-router-dom';
import InfoContainer from "../components/Estoque/InfoContainer.component";
import Layout from "../components/Layout/Layout.component";

import Filter from "../assets/svg/pedidos/filter.svg";
import CalendarIcon from "../assets/svg/pedidos/calendar.svg";
import RefreshIcon from "../assets/svg/estoque/refresh-ccw.svg";
import FilterModal from "../components/Estoque/FilterModal.component";
import PedidoSaida from "../components/Estoque/PedidoSaida.component";
import TableEstoque from "../components/Estoque/TableEstoque.component";
import OrigemModal from "../components/Estoque/OrigemModal.component";
import { Navigate } from "react-router-dom";
export default function Estoque() {
    const [searchFilter, setSearchFilter] = useState('');

    
    let { id } = useParams();

    const [openFilter, setOpenFilter] = useState(false);
    const [modal, setModal] = useState(false);
    const [saida, setSaida] = useState(false);

    const openFilterModal = () => {
        setOpenFilter(prevState => !prevState)
    }

    const openModal = () => {
        setModal(prevState => !prevState)
    }

    const openSaida = () => {
        setSaida(prevState => !prevState)
    }

    if (JSON.parse(localStorage.getItem("@Cliente:permissoes")).estoque != null &&
        JSON.parse(localStorage.getItem("@Cliente:permissoes")).estoque == false) {
        return <Navigate to="/home" />;
    }

    return (
        <>
            <div className={`${saida ? "fixed" : "hidden"} flex items-center p-4 bg-gray-800 bg-opacity-50 z-30 w-full h-full`}>
                {saida ? <PedidoSaida openSaida={openSaida} /> : null}
            </div>

            <div className={`${modal ? "fixed" : "hidden"} flex items-center bg-gray-800 bg-opacity-50 z-30 w-full h-full`}>
                {modal ? <OrigemModal openModal={openModal}/> : null}
            </div>

            <Layout title="Estoque">
                <main className="flex flex-col xl:flex-row mt-6 h-screen">
                    <div className="flex flex-col drop-shadow-xl h-auto w-full">
                        <div className="h-auto bg-white rounded-t-md px-8 py-4">
                            <div className="flex flex-col md:flex-row gap-x-12 gap-y-3">
                                {/* Filtro 
                                <div className="flex flex-col">
                                    <h3 className="font-bold text-sm mb-2">Filtros</h3>

                                    <div
                                        className="flex items-center justify-center border-[1px] border-gray-300 w-10 h-10 rounded-md cursor-pointer"
                                        onClick={openFilterModal}
                                    >
                                        <img src={Filter} alt="Filter Icon" />
                                    </div>

                                    {openFilter && (
                                        <FilterModal openFilterModal={openFilterModal} />
                                    )}
                                </div> */}

                                {/* Pesquisa por data 
                                <div className="flex flex-col">
                                    <h3 className="font-bold text-sm mb-2">Pesquisa por data</h3>

                                    <div className="flex items-center justify-center border-[1px] border-gray-300 w-10 h-10 rounded-md cursor-pointer">
                                        <img src={CalendarIcon} alt="Calendar Icon" />
                                    </div>
                                </div> */}

                                {/* Pesquisa por produto */}
                                <div className="relative flex flex-col">
                                    <label hmtlFor="pesquisaporproduto" className="font-bold text-sm mb-2">Pesquisar</label>

                                    <input
                                        type="text"
                                        id="pesquisaporproduto"
                                        className="border-[1px] border-gray-300 px-3 w-full lg:w-[600px] h-10 rounded-md text-xs"
                                        placeholder="Pesquise por numero nota fiscal"
                                        value={searchFilter}
                                        onChange={(e) => setSearchFilter(e.target.value)}
                                    />
                                </div> 
                            </div>

                            {/* <div className="flex flex-col md:flex-row gap-x-7 gap-y-5 mt-5">
                                <div className="flex items-center gap-x-2">
                                    <input type="checkbox" />
                                    <span className="text-sm">Selecionar todos</span>
                                </div>

                                <div className="flex items-center gap-x-2">
                                    <img src={RefreshIcon} alt="Refresh Icon - Feather Icon" />
                                    <span className="text-sm">Atualizar dados</span>
                                </div>
                            </div> */}
                        </div>

                        <TableEstoque
                            openModal={openModal}
                            produtoId={id}
                            searchFilter={searchFilter}
                        />
                    </div>
                </main>
            </Layout>
        </>
    )
}