import { useState } from "react";

export default function TableValidarERP({ setObjXml, objXml }) {

    const clickEan = (index) => {
        const obj = {...objXml}

        if(obj.produtos[index].alterarEAN != true) {
            obj.produtos[index].alterarEAN = true;
        } else {
            obj.produtos[index].alterarEAN = false;
        }
        setObjXml(obj);   
    };

    const clickNcm = (index) => {
        const obj = {...objXml}

        if(obj.produtos[index].alterarNCM != true) {
            obj.produtos[index].alterarNCM = true;
        } else {
            obj.produtos[index].alterarNCM = false;
        }
        setObjXml(obj);   
    };

    const clickIdentificacao = (index) => {
        const obj = {...objXml}

        if(obj.produtos[index].identificarEntrada != true) {
            obj.produtos[index].identificarEntrada = true;
        } else {
            obj.produtos[index].identificarEntrada = false;
        }
        setObjXml(obj);   
    };
    return (
        <div className="overflow-x-auto overflow-y-auto">
            <table className="w-full text-sm text-left">
                <thead className="text-xs text-white uppercase bg-soulog-900">
                    <tr>
                        <th scope="col" className="py-3 px-12">
                            Código
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Código ERP
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Descrição
                        </th>
                        <th scope="col" className="py-3 px-6">
                            EAN NF
                        </th>
                        <th scope="col" className="py-3 px-6">
                            EAN ERP
                        </th>
                        <th scope="col" className="py-3 px-6">
                            NCM NF
                        </th>
                        <th scope="col" className="py-3 px-6">
                            NCM ERP
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Ações
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {objXml.produtos.map((item, index) => (
                        <tr key={index} className="bg-white border-b-2 border-gray-200">
                            <td className="py-4 px-12 uppercase">
                                {item.codigoProdutoNF}
                            </td>
                            <td className="py-4 px-6 uppercase">
                                {item.produtoBling?.codigo ?? ''}
                            </td>
                            <td className="py-4 px-6 uppercase">
                                {item.descricaoProduto}
                            </td>
                            <td className="py-4 px-6">
                                {item.ean}
                            </td>
                            <td className="py-4 px-6">
                                {item.produtoBling?.gtin ?? ''}
                            </td>
                            <td className="py-4 px-6">
                                {item.ncm}
                            </td>
                            <td className="py-4 px-6">
                                {item.produtoBling?.classFiscal != null ? (item.produtoBling?.classFiscal).replace(/\./gi, '') : ''}
                            </td>
                            <td className="flex flex-col py-4 px-6 w-56 text-sm">
                                <div className="flex items-center mt-[2px]">
                                    <input type="checkbox"
                                        disabled={item.ean == item.produtoBling?.gtin}
                                        onClick={() => clickEan(index)} defaultChecked={item.alterarEAN ?? false}
                                    />
                                    <span className="ml-2">Atualizar EAN</span>
                                </div>
                                <div className="flex items-center mt-[2px]">
                                    <input type="checkbox"
                                        disabled={item.ncm == (item.produtoBling?.classFiscal != null ? (item.produtoBling?.classFiscal).replace(/\./gi, '') : '')}
                                        onClick={() => clickNcm(index)} defaultChecked={item.alterarNCM ?? false}
                                    />
                                    <span className="ml-2">Atualizar NCM</span>
                                </div>
                                <div className="flex items-center mt-[2px]">
                                    <input type="checkbox"
                                        onClick={() => clickIdentificacao(index)} defaultChecked={item.identificarEntrada ?? false}
                                    />
                                    <span className="ml-2">Identificar na entrada</span>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}