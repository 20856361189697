import { useState } from "react";
import Layout from "../components/Layout/Layout.component";
import XmlModal from "../components/Pedidos/XmlModal/XmlModal.component";

import Alert from "../assets/svg/menu/alert-triangle-red.svg"
import Filter from "../assets/svg/pedidos/filter.svg";
import CalendarIcon from "../assets/svg/pedidos/calendar.svg";
import FilterModal from "../components/Pedidos/Filter.component";
import InfoContainer from "../components/Pedidos/InfoContainer.component";
import TablePedidos from "../components/Pedidos/TablePedidos.component";
import DetailsModal from "../components/Pedidos/DetailsModal/DetailsModal.component";
import EtiquetaModal from "../components/Pedidos/EtiquetaModal";
import { Navigate } from "react-router-dom";
export default function Pedidos() {

   

    const [openFilter, setOpenFilter] = useState(false);
    const [openPedidos, setOpenPedidos] = useState(false);
    const [details, setDetails] = useState(false);
    const [options, setOptions] = useState(false);
    const [etiqueta, setEtiqueta] = useState(false);
    const [etiquetaItem, setEtiquetaItem] = useState(null);
    const [filterType, setFilterType] = useState(0);
    const [filterStatus, setFilterStatus] = useState(false);
    const [filterInput, setFilterInput] = useState('');
    const [lengthFilter, setLengthFilter] = useState({ todas: 0, aguardando: 0, novos: 0, separando: 0, embalando: 0, embalado: 0, coletado: 0, erro: 0, cancelado: 0});

    const filters = [
        { title: 'Todas', num: lengthFilter.todas , type: 0 },
        { title: 'Aguardando correção', num: lengthFilter.erro, type: 7 },
        { title: 'Aguardando etiqueta', num: lengthFilter.aguardando, type: 1 },
        { title: 'Aguardando distribuição', num: lengthFilter.novos , type: 2 },
        { title: 'Em separação', num: lengthFilter.separando , type: 3 },
        { title: 'Aguardando embalagem', num: lengthFilter.embalando , type: 4 },
        { title: 'Aguardando coleta', num: lengthFilter.embalado , type: 5 },
        { title: 'Coletado', num: lengthFilter.coletado, type: 6 },
        { title: 'Cancelado', num: lengthFilter.cancelado, type: 8 },
    ]

    const handleClickFilter = (type) => {
        setFilterStatus(!filterStatus);
        setFilterType(type);
    };

    const openFilterModal = () => {
        setOpenFilter(prevState => !prevState)
    }

    const openPedidosModal = () => {
        setOpenPedidos(prevState => !prevState)
    }

    const openDetails = () => {
        setDetails(prevState => !prevState)
    }

    const openOptions = () => {
        setOptions(prevState => !prevState)
    }

    const setCountsFilter = (counts) => {
        setLengthFilter(counts);
    }

    const openEtiqueta = (item) => {
        setEtiquetaItem(item);
        setEtiqueta(prevState => !prevState)
    }

    const handleChangeFilterPedido = (e) => {
        setFilterStatus(!(e.target.value.length == 0));
        setFilterType(99);
        setFilterInput(e.target.value);
    }

    if (JSON.parse(localStorage.getItem("@Cliente:permissoes")).pedidos != null &&
    JSON.parse(localStorage.getItem("@Cliente:permissoes")).pedidos == false) {
        return <Navigate to="/home" />;
    }

    return (
        <>
            <div className={`${openPedidos ? "fixed" : "hidden"} flex items-center bg-gray-800 bg-opacity-50 z-30 w-full h-full`}>
                {openPedidos ? <XmlModal setOpenModal={openPedidosModal} /> : null}
            </div>

            <div className={`${details ? "fixed" : "hidden"} flex items-center bg-gray-800 bg-opacity-50 z-30 w-full h-full`}>
                {details ? <DetailsModal openDetails={openDetails} /> : null}
            </div>

             <div className={`${etiqueta ? "fixed" : "hidden"} flex items-center bg-gray-800 bg-opacity-50 z-30 w-full h-full`}>
                {etiqueta ? <EtiquetaModal openEtiqueta={openEtiqueta} etiquetaItem={etiquetaItem} setEtiquetaItem={setEtiquetaItem}/> : null}
            </div>

            <Layout title="Pedidos">
                <main className="flex flex-col xl:flex-row mt-6 h-screen">
                    <div className="flex flex-col drop-shadow-xl h-auto">
                        <div className="h-auto bg-white rounded-md px-8 py-4">
                            <div className="flex flex-col md:flex-row gap-x-12 gap-y-3">
                                {/* Filtro 
                                <div className="flex flex-col">
                                    <h3 className="font-bold text-sm mb-2">Filtros</h3>

                                    <div
                                        className="flex items-center justify-center border-[1px] border-gray-300 w-10 h-10 rounded-md cursor-pointer"
                                        onClick={openFilterModal}
                                    >
                                        <img src={Filter} alt="Filter Icon" />
                                    </div>

                                    {openFilter && (
                                        <FilterModal openFilterModal={openFilterModal} />
                                    )}
                                </div>

                                <div className="flex flex-col">
                                    <h3 className="font-bold text-sm mb-2">Pesquisa por data</h3>

                                    <div className="flex items-center justify-center border-[1px] border-gray-300 w-10 h-10 rounded-md cursor-pointer">
                                        <img src={CalendarIcon} alt="Calendar Icon" />
                                    </div>
                                </div>
                                */}

                                {/* Pesquisa por produto */}
                                <div className="flex flex-col">
                                    <label hmtlFor="pesquisaporpedido" className="font-bold text-sm mb-2">Pesquisa por pedido ou nota fiscal</label>

                                    <input type="text" 
                                    id="pesquisaporpedido" 
                                    className="border-[1px] border-gray-300 px-3 w-full lg:w-[600px] h-10 rounded-md text-xs" 
                                    placeholder="Pesquise um pedido ou nota fiscal"
                                    onChange={handleChangeFilterPedido} 
                                    disabled={filterStatus == true && filterType != 99}/>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-9 gap-x-3 gap-y-4 mt-4 items-center">
                                {/* Filtros de pesquisa */}
                                {filters.map((item, index) => (
                                    <>

                                        {(item.type != 7 && item.type != 1) &&
                                            <div
                                                className="flex justify-between bg-white drop-shadow-xl rounded-md p-3 h-20"
                                                key={index}
                                            >
                                                <div className="flex flex-col items-start justify-center gap-y-2">
                                                    <input type="checkbox" 
                                                    onClick={() => handleClickFilter(item.type)} 
                                                    disabled={filterStatus == true && filterType != item.type}/>



                                                    <h4 className="text-xs">{item.title}</h4>
                                                </div>

                                                <span className="font-bold">{item.num}</span>
                                            </div>
                                        }

                                        {(item.type == 7 || item.type == 1) &&
                                            <div className="flex justify-between  bg-red-50 drop-shadow-xl rounded-md p-3 h-20 text-sm"
                                            key={index}
                                            >
                                            <div className="flex flex-col items-start justify-center gap-y-2">
                                                <input type="checkbox" className="accent-emerald-600 text-blue-600 border-gray-200 rounded focus:ring-blue-500 mr-2" 
                                                onClick={() => handleClickFilter(item.type)} 
                                                disabled={filterStatus == true && filterType != item.type}/>
                                                
                                                <div className="flex flex-row ">
                                                    <h6 className="text-red-900">{item.title}</h6>
                                                    <img className="ml-5 mt-1" src={Alert}/>
                                                </div>
                                                
                                            </div>

                                            
                                            <span className="font-bold">{item.num}</span>
                                            
                                        </div>
                                        }
                                    </>
                                    

                                    
                                ))}
                            </div>
                        </div>

                        {/* Table */}
                        <TablePedidos
                            details={details}
                            openDetails={openDetails} 
                            options={options} 
                            openOptions={openOptions}
                            openEtiqueta={openEtiqueta}
                            filterStatus={filterStatus}
                            filterType={filterType}
                            filterInput={filterInput}
                            lengthFilter={setCountsFilter}
                        />
                    </div>

                </main>
            </Layout>
        </>
    )
}