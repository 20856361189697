import User from "../../assets/svg/menu/user.svg";
import Bell from "../../assets/svg/menu/bell.svg";
import Filter from "../../assets/svg/filter.svg";
import Settings from "../../assets/svg/settings.svg";
import Check from "../../assets/svg/check.svg";
import Close from "../../assets/svg/x.svg";
import Logout from "../../assets/svg/log-out.svg";
import IntegrationStatus from "./IntegrationStatus.component";
import IntegrationStatusV3 from "./IntegrationStatusV3.component";
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useNewApi } from '../../hooks/useNewApi'

export default function Menu({ title }) {
    const [openProfile, setOpenProfile] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const [blingV3, setBlingV3] = useState(false);

    const newApi = useNewApi();

    const getTokenBlingValido = async () => {
        try {
            const response = await newApi.getTokenBlingValido();

            if (response.status === 200) {
                if (response.data != null) {
                    console.log(response.data)
                    setBlingV3(response.data);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
        }
    };

    useEffect(() => {
        getTokenBlingValido();
    }, []);

    const handleProfile = () => {
        setOpenProfile(prevState => !prevState)
    }

    const handleFilter = () => {
        return;
        setOpenFilter(prevState => !prevState)
    }

    const handleNotifications = () => {
        return;
        setOpenNotifications(prevState => !prevState)
    }

    const handleLogout = async () => {
        try {
            auth.logout();
        } catch (err) {
            console.log(err.message)
        }
    }

    const handleLoginBling = () => {
        // URL de autenticação OAuth2
        var authUrl = 'https://newapiwms.soulog.com.br/api/BlingV3/authBling/' + localStorage.getItem("@Cliente:id");

        const largura = 600;
        const altura = 400;
        // Calcula a posição para centralizar a janela
        const esquerda = (window.screen.width - largura) / 2;
        const topo = (window.screen.height - altura) / 2;

        // Abre a janela no centro da tela
        const opcoes = `width=${largura},height=${altura},left=${esquerda},top=${topo},scrollbars=yes`;


        // Abre a URL em um popup
        var popup = window.open(authUrl, 'Autenticação Bling V3', opcoes);

        // Aguarda o retorno da janela popup
        var timer = setInterval(function () {
            if (popup.closed) {
                clearInterval(timer);
                getTokenBlingValido();
                console.log('Atualizar Status API Bling.');
            }
        }, 1000);
    }

    return (
        <nav className="bg-white drop-shadow-xl rounded-md w-full h-16 mt-6 px-8 sticky top-6 z-10">
            <div className="flex items-center justify-between h-full">
                <div className="relative flex items-center">
                    <h2 className="font-extrabold uppercase ml-8 md:ml-0 mr-4">{title}</h2>

                    {title === "Home" ? (
                        <div className="bg-soulog-900 p-2.5 rounded-md cursor-pointer" onClick={handleFilter}>
                            <img src={Filter} alt="Filter Icon" />
                        </div>
                    ) : null}

                    {openFilter && (
                        <div className="absolute flex flex-col top-0 left-16 bg-white drop-shadow-xl w-[500px] px-6 py-4 rounded-md font-semibold origin-top">
                            <div className="flex flex-row justify-between mb-4">
                                <div className="flex flex-col w-full mr-3">
                                    <label htmlFor="periodo" className="block mb-2 text-sm font-semibold">
                                        Período
                                    </label>

                                    <select id="periodo" className="bg-white border border-gray-800 text-sm rounded-lg block w-full p-2.5">
                                        <option value="todos">Todos</option>
                                    </select>
                                </div>

                                <div className="flex flex-col w-full ml-3">
                                    <label htmlFor="vendas" className="block mb-2 text-sm font-semibold">
                                        Canal de venda
                                    </label>

                                    <select id="vendas" className="bg-white border border-gray-800 text-sm rounded-lg block w-full p-2.5">
                                        <option value="todos">Todos</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex flex-row items-center justify-end">
                                <span className="text-sm underline mr-4">Limpar filtros</span>

                                <button
                                    className="border-2 border-soulog-900 text-soulog-900 font-semibold text-sm rounded-md px-2 py-1 mr-4"
                                    onClick={handleFilter}
                                >
                                    Cancelar
                                </button>

                                <button className="border-2 border-soulog-900 bg-soulog-900 text-white font-semibold text-sm rounded-md px-2 py-1">
                                    Filtrar
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                <div className="hidden xl:flex flex-row ml-64">
                    <h3 className="font-extrabold uppercase">{auth.user.cliente.nome}</h3>
                    <h3 className="font-extrabold ml-5">{auth.user.cliente.cpfCnpj}</h3>
                </div>

                <div className="flex items-center">
                    <div>
                        <IntegrationStatus status="success" />
                        {blingV3 ? (
                            <div>
                                <IntegrationStatusV3 status="success" />
                            </div>
                        ) : (
                            <div onClick={handleLoginBling} className="cursor-pointer">
                                <IntegrationStatusV3 status="error" />
                            </div>
                        )}
                        
                    </div>


                    <div className="relative">

                        <figure className="flex items-center justify-center w-[4rem] h-[2.5rem] cursor-pointer" onClick={handleNotifications}>
                            <img src={Bell} alt="Bell icon" />
                        </figure>


                        {openNotifications && (
                            <div className="absolute flex flex-col -right-6 bg-white drop-shadow-xl w-[330px] pt-3 rounded-md font-semibold origin-top">
                                <h3 className="font-bold text-sm px-3">Notificações</h3>

                                <ul className="flex flex-col mt-2">
                                    <li className="flex flex-row border-t-[2px] justify-center items-center border-gray-200">
                                        <figure className="bg-soulog-100 p-2 rounded-full my-3">
                                            <img src={Check} alt="Check Icon" />
                                        </figure>

                                        <div className="flex flex-col px-3 my-3">
                                            <h4 className="font-bold text-sm">Entrada salva com sucesso</h4>
                                            <p className="font-normal uppercase text-sm">Empresa LOG LTDA</p>
                                        </div>

                                        <img src={Close} alt="Close Icon" />
                                    </li>


                                    <li className="flex flex-row border-t-[2px] justify-center items-center border-gray-200">
                                        <figure className="bg-soulog-100 p-2 rounded-full my-3">
                                            <img src={Check} alt="Check Icon" />
                                        </figure>

                                        <div className="flex flex-col px-3 my-3">
                                            <h4 className="font-bold text-sm">Entrada salva com sucesso</h4>
                                            <p className="font-normal uppercase text-sm">Empresa LOG LTDA</p>
                                        </div>

                                        <img src={Close} alt="Close Icon" />
                                    </li>
                                </ul>
                            </div>
                        )}

                    </div>


                    <p className="hidden md:block font-semibold text-soulog-900 text-sm mr-3">{auth.user.nome}</p>

                    {/* Profile Modal */}
                    <span className="flex items-center justify-center p-2.5 rounded-full bg-soulog-900 cursor-pointer" onClick={handleProfile}>
                        <img src={User} alt="User icon" />
                    </span>

                    {openProfile && (
                        <div className="absolute flex flex-col right-2 top-16 bg-white drop-shadow-xl px-4 py-3 rounded-md font-semibold origin-top">
                            <span className="flex cursor-pointer">
                                Meus dados <img src={Settings} alt="Settings icon" className="ml-2" />
                            </span>

                            <span className="flex justify-end mt-2 cursor-pointer" onClick={handleLogout}>
                                Sair <img src={Logout} alt="Logout icon" className="ml-2" />
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    )
}
