import { useState, useEffect } from "react";
import FilterBox from "../components/B2B/FilterBox.component";
import TableB2B from "../components/B2B/TableB2B.component";
import Layout from "../components/Layout/Layout.component";
import PedidoModal from "../components/B2B/PedidoModal/PedidoModal.component";
import { Navigate } from "react-router-dom";
import DocumentacaoModal from "../components/B2B/DocumentacaoModal";
import DetailsPDFModal from "../components/B2B/DetailsPDFModal/DetailsPDFModal.component";

export default function B2B() {

    const [openModal, setOpenModal] = useState(false);
    const [toggle, setToggle] = useState(true);
    const [tableItems, setTableItems] = useState([]);
    const [updatePedido, setUpdatePedido] = useState(false);

    const [filterTodas, setFilterTodas] = useState({});
    const [filterRegistradas, setFilterRegistradas] = useState({});
    const [filterDocumentacao, setFilterDocumentacao] = useState({});
    const [filterAnalise, setFilterAnalise] = useState({});
    const [filterSeparacao, setFilterSeparacao] = useState({});
    const [filterDespacho, setFilterDespacho] = useState({});
    const [filterAguardandoColeta, setFilterAguardandoColeta] = useState({});
    const [filterColetados, setFilterColetados] = useState({});

    const [filter, setFilter] = useState(false);
    const [filterStatus, setFilterStatus] = useState(null);
    const [editPedido, setEditPedido] = useState({ numeroPedido: '', edit: false });

    const [documentacaoModal, setDocumentacaoModal] = useState(false);
    const [detailsPDFModal, setDetailsPDFModal] = useState(false);
    const [pedidoItem, setPedidoItem] = useState(null);

    useEffect(() => {

        setFilterTodas({
            nome: "Todas",
            quantidade: tableItems.length,
            status: -1,
            ressalva: false
        });

        setFilterRegistradas({
            nome: "Registradas",
            quantidade: tableItems.filter(x => x.status == 0).length,
            status: 0,
            ressalva: false
        });

        setFilterAnalise({
            nome: "Em Análise",
            quantidade: tableItems.filter(x => x.status == 1).length,
            status: 1,
            ressalva: false
        });

        setFilterSeparacao({
            nome: "Em Separação",
            quantidade: tableItems.filter(x => x.status == 2).length,
            status: 2,
            ressalva: false
        });

        setFilterDespacho({
            nome: "Documentação Despacho",
            quantidade: tableItems.filter(x => x.status == 3).length,
            status: 3,
            ressalva: false
        });

        setFilterDocumentacao({
            nome: "Aguardando Documentação",
            quantidade: tableItems.filter(x => x.status == 4).length,
            status: 4,
            ressalva: false
        });

        setFilterAguardandoColeta({
            nome: "Aguardando Coleta",
            quantidade: tableItems.filter(x => x.status == 5).length,
            status: 5,
            ressalva: false
        });

        setFilterColetados({
            nome: "Coletados",
            quantidade: tableItems.filter(x => x.status == 6).length,
            status: 6,
            ressalva: false
        });

    }, [toggle, tableItems]);


    const handleClick = () => {
        setEditPedido({ numeroPedido: '', edit: false });
        setOpenModal(prevState => !prevState);
        setUpdatePedido(false);
    }

    const handleEditPedido = (number) => {
        setEditPedido({ numeroPedido: number, edit: true });
        setOpenModal(true);
        setUpdatePedido(false);
    }

    const openDocumentacaoModal = (item) => {
        setPedidoItem(item);
        setDocumentacaoModal(prevState => !prevState)
        //setUpdatePedido(false);
    }
    const openDetailsModal = (item) => {
        setPedidoItem(item);
        setDetailsPDFModal(prevState => !prevState)
    }


    if (JSON.parse(localStorage.getItem("@Cliente:permissoes")).B2B != null &&
        JSON.parse(localStorage.getItem("@Cliente:permissoes")).B2B == false) {
        return <Navigate to="/home" />;
    }

    return (
        <>
            <div className={`${openModal ? "fixed" : "hidden"} flex items-center bg-gray-800 bg-opacity-50 z-30 w-full h-full`}>
                {openModal ? <PedidoModal editPedido={editPedido} setUpdatePedido={setUpdatePedido} setOpenModal={handleClick} setToggle={setToggle} toggle={toggle} /> : null}
            </div>

            <div className={`${documentacaoModal ? "fixed" : "hidden"} flex items-center bg-gray-800 bg-opacity-50 z-30 w-full h-full`}>
                {documentacaoModal ? <DocumentacaoModal openModal={openDocumentacaoModal} pedidoItem={pedidoItem} openDetailsModal={openDetailsModal} setUpdatePedido={setUpdatePedido} /> : null}
            </div>

            <div className={`${detailsPDFModal ? "fixed" : "hidden"} flex items-center bg-gray-800 bg-opacity-50 z-30 w-full h-full`}>
                {detailsPDFModal ? <DetailsPDFModal openModal={openDetailsModal} pedidoItem={pedidoItem} setPedidoItem={setPedidoItem} /> : null}
            </div>

            <Layout title="Separação B2B">
                <div className="bg-white drop-shadow-xl rounded-md w-full h-auto my-8 px-8 pt-3 pb-10">
                    <h3 className="font-bold text-lg">Lista de separações B2B</h3>

                    <button
                        className="absolute right-8 top-5 lg:right-12 lg:top-8 border-2 border-soulog-900 text-soulog-900 p-2 lg:px-4 lg:py-3 hover:bg-soulog-900 hover:text-white font-bold text-[12px] rounded-md transition-all duration-300"
                        onClick={handleClick}
                    >
                        Nova Separação
                    </button>

                    <div className="flex flex-col xl:flex-row justify-between xl:justify-around mt-6">

                        <FilterBox filter={filterDocumentacao}
                            onClick={() => setFilter(!filter)}
                            passFilterStatus={setFilterStatus}
                            filterStatus={filterStatus}
                            checkFilter={filter}
                            error={true}
                        />

                        <FilterBox filter={filterTodas}
                            onClick={() => setFilter(!filter)}
                            passFilterStatus={setFilterStatus}
                            filterStatus={filterStatus}
                            checkFilter={filter}
                        />

                        <FilterBox filter={filterRegistradas}
                            onClick={() => setFilter(!filter)}
                            passFilterStatus={setFilterStatus}
                            filterStatus={filterStatus}
                            checkFilter={filter}
                        />

                        <FilterBox filter={filterAnalise}
                            onClick={() => setFilter(!filter)}
                            passFilterStatus={setFilterStatus}
                            filterStatus={filterStatus}
                            checkFilter={filter}
                        />

                        <FilterBox filter={filterSeparacao}
                            onClick={() => setFilter(!filter)}
                            passFilterStatus={setFilterStatus}
                            filterStatus={filterStatus}
                            checkFilter={filter}
                        />

                        <FilterBox filter={filterDespacho}
                            onClick={() => setFilter(!filter)}
                            passFilterStatus={setFilterStatus}
                            filterStatus={filterStatus}
                            checkFilter={filter}
                        />

                        <FilterBox filter={filterAguardandoColeta}
                            onClick={() => setFilter(!filter)}
                            passFilterStatus={setFilterStatus}
                            filterStatus={filterStatus}
                            checkFilter={filter}
                        />

                        <FilterBox filter={filterColetados}
                            onClick={() => setFilter(!filter)}
                            passFilterStatus={setFilterStatus}
                            filterStatus={filterStatus}
                            checkFilter={filter}
                        />


                    </div>
                </div>

                <TableB2B
                    toggle={toggle}
                    passTableItems={setTableItems}
                    filterStatus={filterStatus}
                    checkFilter={filter}
                    updatePedido={updatePedido}
                    setEditPedido={handleEditPedido}
                    openDocumentacaoModal={openDocumentacaoModal}
                    openDetailsModal={openDetailsModal}
                />
            </Layout>
        </>
    )
}