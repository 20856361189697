import { useState } from "react";

export default function TableConferirProdutos({ setChecked, setObjXml, objXml}) {

    const setarQtdPacote = (index, value) => {
        const obj = {...objXml}
        obj.produtos[index].qtdPacote = parseInt(value);
        setObjXml(obj);   
    }

    const setarCodigoBling = (index, value) => {
        const obj = {...objXml}
        obj.produtos[index].codigoBlingNovo = value;
        setObjXml(obj);   
    }

    const clickCheck = (index) => {
        const obj = {...objXml}

        if(obj.produtos[index].pacote != true) {
            obj.produtos[index].pacote = true;
        } else {
            obj.produtos[index].pacote = false;
            obj.produtos[index].qtdPacote = '';
        }

        setObjXml(obj);   
        
    };

    
    // TODO Get Table Items
    // Map já está feito lá embaixo.
    // Ao fazer o request só finalizar com setTableItems(data)

    return (
        <div className="overflow-x-auto overflow-y-auto mt-4">
            <table className="w-full text-sm text-left ">
                <thead className="text-xs text-white uppercase bg-soulog-900">
                    <tr>
                        <th scope="col" className="py-3 px-12">
                            Código
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Descrição
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Qtd Itens
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Pacotes
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Qtd Pacote
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Código ERP
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {objXml.produtos.map((item, index) => (
                        <tr className="bg-white border-b-2 border-gray-200" key={index}>
                            <td className="py-4 px-12">
                                {item.codigoProdutoNF}
                            </td>
                            <td className="py-4 px-6 uppercase">
                                {item.descricaoProduto}
                            </td>
                            <td className="py-4 px-6">
                                {item.quantidadeProduto}
                            </td>
                            <td className="py-4 px-6">
                                <input type="checkbox" onClick={() => clickCheck(index)} defaultChecked={item.pacote ?? false}/>
                            </td>
                            <td className="py-4 px-6">
                                <input 
                                    type="number"
                                    className="border-[1px] w-12" 
                                    onChange={({ target }) => setarQtdPacote(index, target.value)} 
                                    value={item.qtdPacote}
                                    disabled={item.pacote == true ? false : true}
                                />
                            </td>
                            <td className="py-4 px-6">
                                {item.codigoProdutoBling ?? 
                                
                                <input type="text" className="border-[1px]" 
                                onChange={({ target }) => setarCodigoBling(index, target.value)} 
                                value={item.codigoBlingNovo ?? ''}
                                />
                                
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}