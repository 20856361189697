export default function InfoBoxBreve({ children, spacing, title}) {
    return (
        <>
            <section className="bg-white drop-shadow-xl rounded-md w-full h-auto my-8 px-8 pt-3 pb-10">
                <h3 className="font-semibold p-1">{title}</h3>

                <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ${spacing} items-center w-full h-full`}>
                    {children}
                </div>
            </section>
            <hr />
        </>
    )
}