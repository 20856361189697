import { useState, useEffect } from "react";
import Row from "./Row.component";
import { useApi } from "../../hooks/useApi";
import PdfIcon from "../../assets/svg/estoque/pdf.svg";
import Moment from 'react-moment';

export default function TableEstoque({ openModal, produtoId, searchFilter }) {
    const [tableItems, setTableItems] = useState({ entradas: [], saidas: [] });

    const [tableItemsTotal, setTableItemsTotal] = useState([])
    const [tableItemsFilter, setTableItemsFilter] = useState([])

    const carregarTableItemTotal = () => {
        let table = [];
        tableItems.entradas.map((item, index) => {
            const obj = {
                data: Date.parse(item.dataEntrada),
                entrada: item.quantidadeEntrada,
                saida: '-',
                numeroNF: item.numeroNF,
                chave: item.entradaId,
                type: 1,
                fullB2B: false
            }
            table.push(obj);
        });

        tableItems.saidas.map((item, index) => {
            const obj = {
                data: Date.parse(item.dataSaida),
                entrada: '-',
                saida: item.quantidadeSaida,
                numeroNF: item.numeroNF,
                chave: item.chaveNF,
                type: 2,
                fullB2B: item.fullB2B ?? false
            }
            table.push(obj);
        });

        table.sort((a, b) => a.data - b.data);
        setTableItemsTotal(table);
    }

    const filterItems = () => {

        let itens = [...tableItemsTotal];
        if (searchFilter.length <= 0) return setTableItemsFilter(itens);

        return setTableItemsFilter(itens.filter(x => x.numeroNF?.toUpperCase().includes(searchFilter.toUpperCase())));
    };

    const api = useApi();
    const getData = async () => {
        try {
            const response = await api.getEstoqueProdutoId(produtoId);

            if (response.status == 200) {
                if (response.data != null) {
                    setTableItems(response.data);
                    carregarTableItemTotal();
                }
            }
        } catch (error) {
            console.log(error);
        }

    };

    useEffect(() => {
        if (tableItems.estoque == null && produtoId > 0) {
            getData();
        }
    }, []);

    useEffect(() => {
        carregarTableItemTotal();
        filterItems();
    }, [tableItems]);

    useEffect(() => {
        filterItems();
    }, [searchFilter, tableItemsTotal]);

    const handleDownloadDanfe = async (chave, type) => {

        let xml = 'Nenhuma nota encontrada';

        if (type == 1) {
            xml = await api.getXmlDanfeEntrada(chave)
        }

        if (type == 2) {
            xml = await api.getXmlDanfeSaida(chave)
        }


        if (xml == 'Nenhuma nota encontrada') {
            alert('Nenhuma nota encontrada');
            return;
        }

        let retorno = await api.gerarDanfe(xml.data);

        let pdfWindow = window.open("")
        pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(retorno.data) + "'></iframe>");
    }

    return (
        <div className="overflow-x-auto overflow-y-auto h-72 lg:h-auto mb-8">
            <table className="w-full text-sm text-left">
                <thead className="sticky top-0 text-xs text-white uppercase bg-soulog-900">
                    <tr>
                        <th scope="col" className="py-3 px-6">
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Data
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Entrada
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Saída
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Origem
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tableItemsFilter.map((item, index) => (
                        <tr key={index} className="bg-white border-b-2 border-gray-200 uppercase">
                            <td className="py-3 px-6">
                            </td>
                            <td className="py-3 px-6">
                                <Moment format="DD/MM/YYYY HH:mm">
                                    {item.data}
                                </Moment>
                            </td>
                            <td className="py-3 px-6">
                                {item.entrada}
                            </td>
                            <td className="py-3 px-6">
                                {item.saida}
                            </td>
                            {item.fullB2B === true ? (
                                <>
                                    <td className="flex gap-x-2 py-3 px-6 cursor-pointer">
                                        <span>FULL B2B - {item.numeroNF}</span>
                                    </td>
                                </>
                            ) :
                                <>
                                    <td className="flex gap-x-2 py-3 px-6 cursor-pointer" onClick={() => handleDownloadDanfe(item.chave, item.type)}>
                                        <img src={PdfIcon} alt="" width="30" />
                                        <span>{item.numeroNF}</span>
                                    </td>
                                </>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}