import { useState, useContext } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import Background from "../assets/login-background.jpeg";
import Logo from "../assets/soulog_extended_logo_colored.png";
import '../styles/login.styles.css'

import { AuthContext } from "../context/Auth/AuthContext";

export default function Login() {
    const auth = useContext(AuthContext);

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    // const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const from = location.state?.from?.pathname || '/'

    if (auth.user) {
        return <Navigate to="/" />;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // if(!formData.email || !formData.password) {
        //     return setError("Preencha todos os campos antes de efetuar o login")
        // }

        try {
            setLoading(true)
            const isLogged = await auth.loginCliente(formData.email, formData.password);

            if(isLogged) {
                <Navigate to="/" />;
            }
        } catch (err) {
            // setError("Houve um erro ao autenticar seu usuário, tente novamente")
        }

        setLoading(false)
    }

    return (
        <section className="gradient">
            <main className="flex flex-col lg:flex-row items-center w-full h-full p-8 xl:p-20">
                <figure className="hidden xl:block w-full xl:w-2/3 h-full">
                    <img src={Background} alt="SouLog - Fulfillment" className="w-full h-full object-cover"/>
                </figure>

                <div className="bg-white w-full h-full xl:w-1/3 p-10">
                    <figure>
                        <img src={Logo} className="w-32" alt="SouLog - Fulfillment" />
                    </figure>

                    <form className="relative mt-8" onSubmit={handleSubmit}>
                        <div className="flex flex-col">
                            <label htmlFor="email" className="font-bold">Email</label>
                            <input 
                                type="email" 
                                id="email" 
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                placeholder="Digite aqui seu email"
                                className="px-3 py-2 my-1 border-[1px] border-black rounded-md placeholder-gray-400 text-sm outline-blue-400"
                            />
                        </div>

                        <div className="flex flex-col mt-3 mb-2">
                            <label htmlFor="password" className="font-bold">Senha</label>
                            <input 
                                type="password"
                                id="password" 
                                onChange={(e) => setFormData({ ...formData, password: e.target.value })} 
                                placeholder="Digite aqui seu email"
                                className="px-3 py-2 my-1 border-[1px] border-black rounded-md placeholder-gray-400 text-sm outline-blue-400"
                            />
                        </div>

                        <span className="absolute text-soulog-900 underline text-sm right-2">Esqueci a senha</span>

                        <button
                            className="bg-soulog-900 w-full py-2.5 rounded-md text-white font-bold mt-12"
                            disabled={loading}
                        >
                            Entrar
                        </button>
                    </form>
                </div>
            </main>
        </section>
    )
}