import React, { useState } from 'react'

export default function ItensDoPedido() {
    const [tableItems, setTableItems] = useState([]);

    return (
        <div className="flex flex-col mt-4 h-56">
                <h3 className="font-bold text-lg">Itens do pedido</h3>

                <div className="overflow-x-auto overflow-y-auto h-auto mb-8">
                    <table className="w-full text-sm text-left mt-3">
                        <thead className="text-xs text-white uppercase bg-soulog-900">
                            <tr>
                                <th scope="col" className="py-3 px-12">
                                    Código
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Descrição
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    QTD Itens
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Pacote?
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    QTD Pacote
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Código ERP
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr className="bg-white border-b-2 border-gray-200">
                                <td className="py-3 px-12">
                                    1234
                                </td>
                                <td className="py-3 px-6 uppercase">
                                    AC CR ORG NATIVE DEMERARA 12PCS X 1KG
                                </td>
                                <td className="py-3 px-6">
                                    10
                                </td>
                                <td className="py-3 px-6">
                                    <input type="checkbox" />
                                </td>
                                <td className="py-3 px-6">
                                    5
                                </td>
                                <td className="py-3 px-6">
                                    AHF1654HGD784KL1561
                                </td>
                            </tr>

                            {/* {tableItems.map((item) => (
                                <tr className="bg-white border-b-2 border-gray-200" key={item.id}>
                                    <td className="py-3 px-12">
                                        {item.codigo}
                                    </td>
                                    <td className="py-3 px-6 uppercase">
                                        {item.desc}
                                    </td>
                                    <td className="py-3 px-6">
                                        {item.qtdItems}
                                    </td>
                                    <td className="py-3 px-6">
                                        <input type="checkbox" />
                                    </td>
                                    <td className="py-3 px-6">
                                        {item.qtdPacote}
                                    </td>
                                    <td className="py-3 px-6">
                                        {item.codigoERP}
                                    </td>
                                </tr>
                            ))} */}
                        </tbody>
                    </table>
                </div>
            </div>
    )
}
