import InfoBoxBreve from "../components/InfoBox/InfoBoxBreve.component";
import Layout from "../components/Layout/Layout.component";

export default function Breve({previsto}) {

    return (
        <Layout title="Em Breve">
            <InfoBoxBreve spacing="xl:grid-cols-6" previsto={previsto}>
            </InfoBoxBreve>
        </Layout>
    )
}