import { useState, useEffect } from "react";
import Row from "./Row.component";
import { useApi } from "../../hooks/useApi";

export default function TablePedidos({ details, openDetails, options, openOptions, openEtiqueta, filterStatus, filterType, filterInput, lengthFilter }) {

    const [tableItems, setTableItems] = useState([]);
    const [tableItemsFilter, setTableItemsFilter] = useState([]);

    const api = useApi();

    useEffect(() => {

        filterItems();

    }, [filterType, filterStatus, filterInput]);

    const filterItems = () => {

        let itens = [...tableItems];

        if(filterStatus == false) return setTableItemsFilter(itens.filter(x => x.statusSeparacao != 99 && x.statusSeparacao != 98));

        switch(filterType) {
            case 0:
                return setTableItemsFilter(itens);
            case 1:
                return setTableItemsFilter(itens.filter(x => x.statusSeparacao == 11));
            case 2:
                return setTableItemsFilter(itens.filter(x => x.statusSeparacao == 1 || x.statusSeparacao == 2|| x.statusSeparacao == 13 || x.statusSeparacao == 14));
            case 3:
                return setTableItemsFilter(itens.filter(x => x.statusSeparacao >= 6 && x.statusSeparacao <= 8));
            case 4:
                return setTableItemsFilter(itens.filter(x => x.statusSeparacao == 9 || x.statusSeparacao == 12));
            case 5:
                return setTableItemsFilter(itens.filter(x => x.statusSeparacao == 10));
            case 6:
                return setTableItemsFilter(itens.filter(x => x.statusSeparacao == 99));
            case 7:
                return setTableItemsFilter(itens.filter(x => x.statusSeparacao == 4 || x.statusSeparacao == 5));
            case 8:
                return setTableItemsFilter(itens.filter(x => x.statusSeparacao == 98));
            case 99:
                return setTableItemsFilter(itens.filter(x => x.numeroPedido.includes(filterInput) || x.numeroNF?.includes(filterInput)));

            default:
                return setTableItemsFilter(itens);
        } 
    };

    const setLengthFilter = () => {
        const counts = { todas: [...tableItems].length, 
                         aguardando: [...tableItems].filter(x => x.statusSeparacao == 11).length, 
                         novos: [...tableItems].filter(x => x.statusSeparacao == 1 || x.statusSeparacao == 2|| x.statusSeparacao == 13 || x.statusSeparacao == 14).length, 
                         separando: [...tableItems].filter(x => x.statusSeparacao >= 6 && x.statusSeparacao <= 8).length, 
                         embalando: [...tableItems].filter(x => x.statusSeparacao == 9 || x.statusSeparacao == 12).length, 
                         embalado: [...tableItems].filter(x => x.statusSeparacao == 10).length,
                         coletado: [...tableItems].filter(x => x.statusSeparacao == 99).length, 
                         erro: [...tableItems].filter(x => x.statusSeparacao == 4 || x.statusSeparacao == 5).length,
                         cancelado: [...tableItems].filter(x => x.statusSeparacao == 98).length
        }

        lengthFilter(counts);
    };


    const getData = async ()=> {
        try {
          const response = await api.getPedidosSeparacaoDia();
          
          if(response.status == 200) {
            if(response.data.pedidosSeparacao != null) {
                setTableItems(response.data.pedidosSeparacao);

                const responseFull = await api.getPedidosSeparacao();
          
                if(responseFull.status == 200) {
                    if(responseFull.data.pedidosSeparacao != null) {
                        setTableItems(responseFull.data.pedidosSeparacao);
                    }
                }
            }
          }
        } catch (error) {
          console.log(error);
        }
    
    };

      
    useEffect(() => {
        if(tableItems.length <= 0)
            getData();
    }, []);

    useEffect(() => {
        filterItems();
        setLengthFilter();
    }, [tableItems]);


    return (
        <div className="overflow-x-auto overflow-y-auto h-auto mb-8">
            <table className="w-full text-sm text-left">
                <thead className="sticky top-0 text-xs text-white uppercase bg-soulog-900">
                    <tr>
                        {/*<th scope="col" className="py-3 px-6"></th>*/}
                        <th scope="col" className="py-3 px-6">
                            Número Pedido
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Número NF
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Data
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Canal de Venda
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Status
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tableItemsFilter.map((item) => (
                        <Row
                            key={item.idSistema}
                            details={details}
                            openDetails={openDetails}
                            options={options}
                            openOptions={openOptions}
                            item={item}
                            openEtiqueta={openEtiqueta}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}