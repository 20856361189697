import { useState, useEffect } from 'react';
import Select from 'react-select'
import { useApi } from '../../../../hooks/useApi'
import { toast, ToastContainer } from 'react-toastify';

export default function PedidoVenda({ objPedido, setObjPedido, setErrorMsg, setError, editPedido, setCurrentStep, setLoading, loading }) {
    const [canais, setCanais] = useState([]);
    const [contatos, setContatos] = useState([]);
    const api = useApi();

    const getCanais = async () => {
        try {
            setLoading(true);
            const response = await api.getListaCanalVenda();

            if (response.status === 200) {
                if (response.data != null) {
                    let data = response.data.map(x => {
                        return { value: x.idCanalVenda, label: x.nomeCanalVenda };
                    });

                    setCanais(data);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getContatos = async () => {
        try {
            const response = await api.getListaContatos();

            if (response.status === 200) {
                if (response.data != null) {
                    let data = response.data.map(x => {
                        return { value: x.idContato, label: x.nomeContato };
                    });

                    setContatos(data);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const atualizarContatos = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            const response = await api.atualizaContatos();

            if (response.status === 200) {
                if (response.data != null) {
                    let data = response.data.map(x => {
                        return { value: x.idContato, label: x.nomeContato };
                    });

                    setContatos(data);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (editPedido.edit == true) {
            handlerPedidoBling(editPedido.numeroPedido);
            setObjPedido(
                {
                    ...objPedido,
                    pedidoBling: editPedido.numeroPedido,
                    isPedidoBling: true
                });

            sincronizaBling(null);
            return;
        }
        console.log(objPedido)
        getCanais();
        getContatos();
    }, []);

    const handlerCanal = (value) => {

        let pedidoInterno = objPedido.pedidoInterno;
        pedidoInterno.canalVenda = value.label;

        setObjPedido({ ...objPedido, idCanalVenda: value.value, pedidoInterno });
    }

    const handlerContatos = (value) => {
        setObjPedido({ ...objPedido, idContato: value.value });
    }

    const handlerPedidoBling = (value) => {
        setObjPedido({ ...objPedido, pedidoBling: value });
    }

    const sincronizaBling = async (e) => {
        if (e != null)
            e.preventDefault();

        try {
            setLoading(true);
            setError(false);

            const numeroPedido = editPedido.edit == true ? editPedido.numeroPedido : objPedido.pedidoBling;

            const response = await api.sincronizarPedido(numeroPedido);

            if (response.status === 200) {
                if (response.data != null) {

                    if (editPedido.edit != true) {
                        let obj = { ...objPedido };
                        response.data.canalVenda = obj.pedidoInterno.canalVenda;
                    }

                    setObjPedido({ ...objPedido, pedidoInterno: response.data, idPedidoBling: response.data.idPedidoBling });
                    toast.success('Pedido sincronizado com sucesso!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    console.log(response.data, objPedido)

                    if (objPedido?.pedidoInterno?.canalVenda != null || editPedido.edit == true)
                        setCurrentStep(2);
                }
            }
        } catch (error) {
            console.log(error)
            if (error?.response?.status === 500) {
                const match = error.response.data.match(/\[B2B\] [^\r\n]+/);
                if (match) {
                    const result = match[0];
                    setErrorMsg(result);
                } else {
                    console.log(error.data);
                    setErrorMsg('Não foi possivel sincronizar, favor verificar dados informados.');
                }

                setError(true);
            }
            console.log('Erro: ', error);
        } finally {
            setLoading(false);
        }
    }

    const handlerIsBling = (e) => {
        setObjPedido({ ...objPedido, pedidoBling: '', isPedidoBling: !e.target.checked });
    }

    return (
        <div className="px-8 py-4 w-full h-full">
            <h3 className="font-bold text-lg">Preencha as informações abaixo</h3>

            <form className="flex flex-col my-4">
                <div className="d-flex flex-col w-full xl:w-[50%]">
                    <label htmlFor="canalVenda" className="font-semibold text-sm">Canal de Venda</label>
                    <Select
                        options={canais}
                        className="my-1 mb-2"
                        isDisabled={loading}
                        noOptionsMessage={() => "Nenhum canal encontrado."}
                        placeholder="Escolha um canal"
                        onChange={value => handlerCanal(value)} />
                </div>
                <div className='flex'>
                    <div className="d-flex flex-col w-1/4">
                        <label htmlFor="pedidoBling" className="flex-col font-semibold text-sm">N° Pedido Bling: </label>
                        <input
                            type="number"
                            id="pedidoBling"
                            value={objPedido.pedidoBling}
                            onChange={(e) => handlerPedidoBling(e.target.value)}
                            className="border-[1px] rounded-md my-1 p-2"
                            disabled={!objPedido.isPedidoBling || loading}
                        />
                    </div>
                    <div className="flex-initial w-1/4">
                        <button
                            className={`bg-white border-2 border-soulog-900 text-soulog-900 text-sm 
                            font-semibold rounded-md ml-2 mt-4 px-5 py-2 
                                    ${loading ||
                                    !objPedido.isPedidoBling ||
                                    (objPedido.pedidoBling == null || objPedido.pedidoBling?.length <= 0) ||
                                    objPedido.idPedidoBling != null ? "opacity-50 cursor-not-allowed" : null}`}
                            onClick={(e) => sincronizaBling(e)}
                            disabled={!objPedido.isPedidoBling}
                        >
                            {(loading && objPedido.isPedidoBling) ? "Sincronizando..." : objPedido.idPedidoBling == null ? "Sincronizar" : "Sincronizado"}
                        </button>
                    </div>
                </div>
                <div className="flex items-center mt-2">
                    <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5"
                        onChange={(e) => handlerIsBling(e)}
                        disabled={objPedido.idPedidoBling != null}
                    />
                    <label className="ml-2">Continuar sem pedido no Bling.</label>
                </div>
                <div className='flex'>
                    <div className="flex flex-col w-2/4 mt-3">
                        <div className="flex flex-col w-full">
                            <label htmlFor="dataPrevista" className="font-semibold text-sm">Destinatário</label>
                            <Select
                                options={contatos}
                                className="my-1 p-2"
                                isDisabled={loading || objPedido.isPedidoBling}
                                isLoading={loading}
                                noOptionsMessage={() => "Nenhum destinatário encontrado."}
                                placeholder="Escolha um destinatário"
                                onChange={value => handlerContatos(value)} />
                        </div>
                    </div>

                    <div className="flex-initial xl:flex-row mt-5">
                        <button
                            className={`bg-white border-2 border-soulog-900 text-soulog-900 text-sm 
                        font-semibold rounded-md px-5 py-2 ${loading || objPedido.pedidoInterno != null || objPedido.isPedidoBling ? "opacity-50 cursor-not-allowed" : null}`}
                            onClick={(e) => atualizarContatos(e)}
                            disabled={objPedido.pedidoInterno != null || objPedido.isPedidoBling}
                        >
                            {(loading && !objPedido.isPedidoBling) ? "Atualizando..." : "Atualizar Contatos"}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
