import { useEffect, useRef, useState } from 'react'

export default function Stepper({ steps, currentStep }) {
    const [newStep, setNewStep] = useState([])
    const stepRef = useRef()

    const updateStep = (stepNumber, steps) => {
        const newSteps = [...steps]
        let count = 0

        while (count < newSteps.length) {
            // current step
            if (count === stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: true,
                    selected: true,
                    completed: true
                }
                count++
            }
            // step completed
            else if (count < stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: true,
                    completed: true
                }
                count++
            }
            // step pending
            else {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: false,
                    completed: false
                }
                count++
            }
        }

        return newSteps;
    }

    useEffect(() => {
        const stepState = steps.map((step, index) =>
            Object.assign({}, {
                description: {
                    step: {
                        title: step.title,
                        desc: step.desc
                    }
                },
                completed: false,
                highlighted: index === 0 ? true : false,
                selected: index === 0 ? true : false
            })
        )

        stepRef.current = stepState
        const current = updateStep(currentStep - 1, stepRef.current)
        setNewStep(current)
    }, [steps, currentStep])

    const displaySteps = newStep.map((step, index) => {
        return (
            <div className="flex mx-10 my-2 w-full" key={index}>
                <div className="flex flex-row items-center">
                    <div className={`rounded-md transition duration-500 ease-in-out text-white 
                    font-semibold h-12 w-12 flex items-center justify-center py-3 ${step.selected ? "bg-soulog-900" : "bg-zinc-500"}`}>
                        {step.completed ? (
                            <span className="text-white font-semibold">&#10003;</span>
                        ) : (index + 1)}
                    </div>

                    <div className={`text-left ml-3 text-xs font-semibold uppercase ${step.highlighted ? "text-gray-900" : "text-gray-400"}`}>
                        <h1 className="font-semibold text-[15px]">{step.description.step.title}</h1>
                        <h3 className="font-normal text-xs">{step.description.step.desc}</h3>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className="grid grid-rows-1 lg:grid-cols-3 pt-3">
            {displaySteps}
        </div>
    )
}
