import { useState } from "react";
import { UploadCloud } from "react-feather";
import { getBase64 } from "../../../helpers/functionsHelps";
import FileText from "../../../assets/svg/file-text.svg"
import X from "../../../assets/svg/x.svg"
import { useApi } from '../../../hooks/useApi'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { base64MimeType, getTextFile } from "../../../helpers/functionsHelps"

const MySwal = withReactContent(Swal);

export default function EtiquetaModal({ openEtiqueta, etiquetaItem, setEtiquetaItem }) {

    const [file, setFile] = useState('')
    const [fileName, setFileName] = useState('')
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const api = useApi();

    const onFileDrop = async (e) => {
        if(e.target.files.length > 0) {
            const newFile = e.target.files[0]
            if (newFile) {
                setError(false);

                let base = await getBase64(newFile)
                setFile(base);

                let nome = newFile.name;
                let resumo = '';
                if (nome.length > 30) {
                    resumo = nome.slice(0, 15);
                    resumo += '...' + nome.slice((nome.length - 15), nome.length);
                    setFileName(resumo);
                } else {
                    setFileName(nome);
                }
            }
        }
    }

    const handleDeleteFile = () => {
        setError(false);
        setFile('');
        setLoading(false);
    }

    const handleClick = async () => {

        setLoading(true);
        if(file == null || file == '') {
            setError(true);
            setErrorMsg("Favor selecionar o arquivo PDF.");
            return;
        }
        
        if(file != '' && base64MimeType(file) != 'application/pdf') {
            setError(true);
            setErrorMsg("Arquivo PDF inválido.");
            setLoading(false);
            return;
        } else {

            etiquetaItem.etiquetaEnvioBase64 = file;
            let ret = await api.gravarEtiquetaEnvio(etiquetaItem);

            if (ret.data == null || ret.data.id == null || ret.status != 200) {
                setError(true);
                setErrorMsg("Erro no servidor, tente novamente mais tarde.");
                return;
            }

            etiquetaItem.statusSeparacao = 9;
            setEtiquetaItem(etiquetaItem);

            await MySwal.fire({
                icon: 'success',
                title: 'Etiqueta enviada com sucesso.',
                text: 'Seu pedido está pronto para ser embalado.',
                confirmButtonColor: "#00948b"
            });

            openEtiqueta(null);
            handleDeleteFile();
            
        }       
    }


    return (
        <div className="relative flex flex-col justify-center w-[85%] xl:w-[70%] mx-auto rounded-md px-5 py-5 bg-white drop-shadow-2xl">
            <span
                className="absolute -top-4 -right-4 bg-white drop-shadow-xl px-3 py-2.5 rounded-md text-soulog-900 font-bold cursor-pointer"
                onClick={() => openEtiqueta(null)}
            >
                X
            </span>

            <h1 className="font-bold text-sm md:text-lg">Anexar Etiqueta</h1>

            <>
                { !file && (
                    <>
                    <div className="relative flex flex-col justify-center items-center border-dashed border-[1px] border-gray-500 px-3 h-20 lg:h-40 my-3">
                        <input type="file" className="absolute w-full h-full cursor-pointer top-0 left-0 opacity-0" onChange={onFileDrop} />

                        <p className="font-semibold text-xs md:text-lg p-3">Arraste e solte seu arquivo aqui</p>
                    </div>

                    <div className="relative flex py-3 justify-center items-center">
                        <div className="flex w-1/4 border-t border-gray-400"></div>
                        <span className="flex-shrink mx-4 font-semibold text-gray-500">ou</span>
                        <div className="flex w-1/4 border-t border-gray-400"></div>
                    </div>

                    <div className="flex flex-col justify-center items-center my-3">
                        <button className="relative border-[2px] border-soulog-900 text-soulog-900 text-xs md:text-sm font-semibold rounded-md px-4 py-2">
                            <input type="file" className="absolute w-full h-full cursor-pointer top-0 left-0 opacity-0" value="" onChange={onFileDrop} />
                            Selecionar um arquivo na sua máquina
                        </button>
                    </div>
                    </>
                )}

                {file && 
                    <div className="flex flex-col justify-center items-center px-8 py-4">
                        <>
                            <div className="relative flex flex-row justify-start ring-offset-2 ring-1 ring-gray-500 rounded-sm  px-3 h-14 w-96 my-3">
                            
                                <img src={FileText} alt="File Icon" className="my-2 w-6"/>
                                <p className="font-bold text-md my-3 ml-1">{fileName}</p>
                                <button className="absolute top-0 right-0 inline-block align-middle" onClick={handleDeleteFile}>
                                    <img src={X} alt="Close Icon" className="my-2 mr-2 w-4 rounded-full border-black border-solid border-2 "/>
                                </button>
                            </div>

                            {error &&
                                <div className="xl:mt-5 w-full">
                                    <span className="flex flex-col justify-center text-center bg-red-300 py-2 px-3 rounded-2">
                                        {errorMsg}
                                    </span>
                                </div>
                            }

                            <button
                                className="bg-soulog-900 border-2 border-soulog-900 text-white text-sm font-semibold rounded-md px-5 py-2 mt-9"
                                onClick={ handleClick }
                                disabled={ loading }
                            >
                                {loading &&
                                    <svg role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4B8r3B4p7yhRXuBWLqsQ546WR43cqQwrbXMDFnBi6vSJBeif8tPW85a7r7DM961Jvk4hdryZoByEp8GC8HzsqJpRN4FxGM9 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#66b2b2"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.4B8r3B4p7yhRXuBWLqsQ546WR43cqQwrbXMDFnBi6vSJBeif8tPW85a7r7DM961Jvk4hdryZoByEp8GC8HzsqJpRN4FxGM9 1.27873C39.2613 1.69328 37.813 4.19778 38.4B8r3B4p7yhRXuBWLqsQ546WR43cqQwrbXMDFnBi6vSJBeif8tPW85a7r7DM961Jvk4hdryZoByEp8GC8HzsqJpRN4FxGM9.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                    </svg>
                                }   

                                Enviar Etiqueta
                            
                            </button>
                        </>
                    </div>
                }

            </>
        </div>
    )
}