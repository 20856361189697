import { useState } from "react"

export default function ChooseShipment() {
    const [file, setFile] = useState(null)

    const onFileDrop = (e) => {
        const newFile = e.target.files[0]

        if (newFile) {
            const updateList = [...file, newFile]
            setFile(updateList)
        }
    }

    return (
        <div className="flex flex-col justify-center px-8 py-4">
            <h3 className="font-bold text-lg">Anexar etiqueta de envio</h3>

            <>
                <div className="relative flex flex-col justify-center items-center border-dashed border-2 border-gray-500 bg-gray-500 bg-opacity-10 px-3 py-5 h-auto my-3">
                    <input type="file" className="absolute w-full h-full cursor-pointer top-0 left-0 opacity-0" value="" onChange={onFileDrop} />

                    <p className="font-semibold text-md">Arraste e solte seu arquivo aqui</p>

                    <span className="font-semibold my-1">ou</span>

                    <p className="font-semibold text-md">Selecione um arquivo na sua máquina</p>
                </div>
            </>

            <div className="relative flex py-3 justify-center items-center">
                <div className="flex w-1/4 border-t border-gray-400"></div>
                <span className="flex-shrink mx-4 font-semibold text-gray-500">ou</span>
                <div className="flex w-1/4 border-t border-gray-400"></div>
            </div>

            <h3 className="font-bold text-lg">Selecionar modalidade</h3>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4 mt-4">
                <div className="flex flex-row justify-between items-center bg-white drop-shadow-xl rounded-md p-3 text-sm">
                    <input type="checkbox"/>

                    <div className="flex flex-col gap-y-2">
                        <h3 className="font-semibold">Normal</h3>

                        <p className="font-normal">de 5 a 7 dias úteis</p>
                    </div>

                    <p>R$12,34</p>
                </div>

                <div className="flex flex-row justify-between items-center bg-white drop-shadow-xl rounded-md p-3 text-sm">
                    <input type="checkbox"/>

                    <div className="flex flex-col gap-y-2">
                        <h3 className="font-semibold">Normal</h3>

                        <p className="font-normal">de 5 a 7 dias úteis</p>
                    </div>

                    <p>R$12,34</p>
                </div>

                <div className="flex flex-row justify-between items-center bg-white drop-shadow-xl rounded-md p-3 text-sm">
                    <input type="checkbox"/>

                    <div className="flex flex-col gap-y-2">
                        <h3 className="font-semibold">Normal</h3>

                        <p className="font-normal">de 5 a 7 dias úteis</p>
                    </div>

                    <p>R$12,34</p>
                </div>

                <div className="flex flex-row justify-between items-center bg-white drop-shadow-xl rounded-md p-3 text-sm">
                    <input type="checkbox"/>

                    <div className="flex flex-col gap-y-2">
                        <h3 className="font-semibold">Normal</h3>

                        <p className="font-normal">de 5 a 7 dias úteis</p>
                    </div>

                    <p>R$12,34</p>
                </div>
            </div>
        </div>
    )
}