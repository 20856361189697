import React from 'react'

export default function StepperControl({ handleClick, currentStep, setOpenModal }) {
    return (
        <div className="container flex justify-between mt-3 mb-8">
            <button
                className={`bg-white border-2 border-soulog-900 text-soulog-900 text-sm 
                font-semibold rounded-md px-5 py-2 ${currentStep === 1 ? "opacity-50 cursor-not-allowed" : null}`}
                onClick={() => handleClick()}
            >
                Voltar
            </button>

            <button
                className="bg-soulog-900 border-2 border-soulog-900 text-white text-sm font-semibold rounded-md px-5 py-2"
                onClick={() => {
                    handleClick("next")
                    if(currentStep === 3) {
                        setOpenModal()
                    }
                }}
            >
                {currentStep === 3 ? "Finalizar" : "Avançar"}
            </button>
        </div>
    )
}
