import React from 'react'
import Erros from './Erros.component'
import HistoricoTransicao from './HistoricoTransicao.component'
import ItensDoPedido from './ItensDoPedido.component'

export default function DetailsModal({ openDetails }) {
    return (
        <div className="flex flex-col justify-center w-[85%] xl:w-[70%] mx-auto rounded-md px-5 py-3 bg-white drop-shadow-2xl">
            <span
                className="absolute -top-4 -right-4 bg-white drop-shadow-xl px-3 py-2.5 rounded-md text-soulog-900 font-bold cursor-pointer"
                onClick={() => openDetails()}
            >
                X
            </span>

            <ItensDoPedido />

            <HistoricoTransicao />

            <Erros />

            <div className="flex justify-center items-center">
                <button
                    className="border-2 border-soulog-900 text-soulog-900 hover:bg-soulog-900 hover:text-white transition-all duration-300 ease-in-out font-semibold p-2 w-56 rounded-md"
                    onClick={() => openDetails()}
                >
                    Fechar
                </button>
            </div>
        </div>
    )
}
