import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import Logo from "../../assets/soulog_logo.png";
import LogoExtended from "../../assets/soulog_extended_logo.png";

// icons
import Menu from "../../assets/svg/menu.svg";
import Home from "../../assets/svg/sidebar/home.svg";
import List from "../../assets/svg/sidebar/list.svg";
import Package from "../../assets/svg/sidebar/package.svg";
import Clipboard from "../../assets/svg/sidebar/clipboard.svg";
import Share1 from "../../assets/svg/sidebar/share.svg";
import Code from "../../assets/svg/sidebar/code.svg";
import Bag from "../../assets/svg/sidebar/shopping-bag.svg";
import Share2 from "../../assets/svg/sidebar/share-2.svg";
import Users from "../../assets/svg/sidebar/users.svg";
import FileText from "../../assets/svg/sidebar/file-text.svg";
import Info from "../../assets/svg/sidebar/info.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export default function Sidebar() {
    const [open, setOpen] = useState(false)

    const [permissoes, setPermissoes] = useState({})

    useEffect(() => {
        setPermissoes(JSON.parse(localStorage.getItem("@Cliente:permissoes")));
    }, [])

    useEffect(() => {
        console.log(permissoes);
    }, [permissoes])


    const menuItems = [
        {
            url: '/home',
            icon: Home,
            title: 'Home',
            visible: true
        },
        {
            url: '/entrada',
            icon: List,
            title: 'Entrada',
            visible: permissoes.entrada ?? true
        },
        {
            url: '/estoque',
            icon: Package,
            title: "Estoque",
            visible: false //permissoes.estoque ?? true
        },
        {
            url: '/pedidos',
            icon: Clipboard,
            title: "Pedidos",
            visible: permissoes.pedidos ?? true
        },
        {
            url: '/devolucao',
            icon: Share1,
            title: "Devolução",
            visible: permissoes.devolucao ?? true
        },
        {
            url: '/sepb2b',
            icon: Code,
            title: "Separação B2B",
            visible: permissoes.b2B ?? true
        },
        {
            url: '/produtos',
            icon: Bag,
            title: "Produtos",
            visible: permissoes.produtos ?? true
        },
        {
            url: '/integracoes',
            icon: Share2,
            title: "Integrações",
            visible: permissoes.integracoes ?? true
        },
        {
            url: '/empresa',
            icon: Users,
            title: "Empresa",
            visible: permissoes.empresa ?? true
        },
        {
            url: '/relatorios',
            icon: FileText,
            title: "Relatórios",
            visible: permissoes.relatorios ?? true
        },
        {
            url: '/suporte',
            icon: Info,
            title: "Suporte",
            visible: true
        }
    ]

    return (
        <>
            <button className="fixed md:hidden top-[37px] left-12 p-2 rounded-md text-white font-semibold z-20"
                onClick={() => setOpen(prevState => !prevState)}>
                <img src={Menu} alt="Menu Icon" />
            </button>

            <div
                className={`${!open && "hidden"} fixed md:flex flex-col ${open ? "w-full md:w-72" : "w-[5.3rem]"} min-h-screen bg-soulog-900 duration-500 text-white py-8 z-20`}
                onMouseOver={() => setOpen(true)}
                onMouseOut={() => setOpen(false)}
            >
                {/* Logo */}
                <figure className="flex items-center justify-center transition-all duration-300 ease-in-out">
                    <Link to="/">
                        {open ? (
                            <img src={LogoExtended} alt="SouLog - Fulfillment" className="transition-all duration-300 ease-in-out origin-left h-12 cursor-pointer" />
                        ) : (
                            <img src={Logo} alt="SouLog - Fulfillment" className="transition-all duration-300 ease-in-out origin-right h-12 cursor-pointer" />
                        )}
                    </Link>
                </figure>

                <div className={`absolute lg:hidden flex items-center rounded-full top-8 right-8 px-[15px] py-[10px] bg-white z-30`}>
                    <FontAwesomeIcon
                        icon={faClose}
                        className="text-2xl text-soulog-900"
                        onClick={() => setOpen(prevState => !prevState)}
                    />
                </div>

                <ul className="flex flex-col justify-center gap-4 mt-12 px-[1.9rem]">
                    {menuItems.filter(x => x.visible == true).map((item, index) => (
                        <Link to={item?.url} key={index} onClick={() => setOpen(false)}>
                            <li className={`flex items-center gap-x-4 mt-2 font-semibold text-white`}>
                                <img src={item.icon} alt="Menu Item Icon" />
                                <span className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-20 overflow-hidden"}`}>
                                    {item.title}
                                </span>
                            </li>
                        </Link>
                    ))}
                </ul>
            </div>
        </>
    )
}