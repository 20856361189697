import { useState } from 'react';
import TableConferirProdutosB2B from '../../../Table/TableConferirProdutosB2B.component';
import Form from '../Form.component';
import { useApi } from '../../../../hooks/useApi'

import { toast, ToastContainer } from 'react-toastify';

export default function CheckProducts({ objPedido, setObjPedido, setErrorMsg, setError }) {
    const [checked, setChecked] = useState(false);
    const [produto, setProduto] = useState({});
    const [editProduto, setEditProduto] = useState(false);
    const [loading, setLoading] = useState(false);
    const api = useApi();

    const checkItem = () => {
        setChecked(prevState => !prevState)
    }

    const buscaProduto = async (codigo) => {
        try {
            setLoading(true);
            const response = await api.buscaProduto(codigo);

            if (response.status === 200) {
                if (response.data != null) {
                    return response.data;
                }
            }
        } catch (error) {
            toast.error('Produto não encontrado!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            setProduto({ codigoBling: '', quantidade: '', valor: '' });
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handlerAdicionaProduto = async () => {
        if (editProduto) {
            const obj = { ...objPedido };

            //let produtoPedido = obj.pedidoInterno.itensPedidoInterno[produto.index];

            //obj.pedidoInterno.itensPedidoInterno.splice(produto.index, 1);

            obj.pedidoInterno.itensPedidoInterno[produto.index].quantidade = parseFloat(produto.quantidade);
            obj.pedidoInterno.itensPedidoInterno[produto.index].valorUnitario = parseFloat(produto.valor);
            obj.pedidoInterno.itensPedidoInterno[produto.index].valorTotal = (parseFloat(produto.valor) * parseFloat(produto.quantidade));

            //console.log('OB', obj)
            //obj.pedidoInterno.itensPedidoInterno.push(produtoPedido);

            setObjPedido(obj);
            setEditProduto(false);
            setProduto({ codigoBling: '', quantidade: '', valor: '' })
        } else {
            if (produto?.codigoBling != null && produto?.quantidade != null && produto?.valor != null) {
                const obj = { ...objPedido };
                const novoProduto = await buscaProduto(produto?.codigoBling);

                if(novoProduto == null || novoProduto == undefined) return;

                novoProduto.quantidade = parseFloat(produto.quantidade);
                novoProduto.valorUnitario = parseFloat(produto.valor);
                novoProduto.valorTotal = parseFloat(produto.valor) * parseInt(produto.quantidade);

                obj.pedidoInterno.itensPedidoInterno.push(novoProduto);

                setObjPedido(obj);
                setProduto({ codigoBling: '', quantidade: '', valor: '' })

            }

        }
    };

    return (
        <>
            <div className="px-8 py-4 w-full h-full">
                <h3 className="font-bold text-lg">{editProduto ? "Editar" : "Adicionar"} produto</h3>
                <div className='flex mt-2'>
                    <div className="flex flex-col w-1/4">
                        <label htmlFor="codigoBling" className="flex-col font-semibold text-sm">SKU Bling: </label>
                        <input
                            type="text"
                            id="codigoBling"
                            value={produto.codigoBling}
                            onChange={(e) => setProduto({ ...produto, codigoBling: e.target.value })}
                            className="border-[1px] rounded-md my-1 p-2"
                            disabled={loading || editProduto}
                        />
                    </div>
                    <div className="flex flex-col w-1/4 ml-2">
                        <label htmlFor="quantidade" className="flex-col font-semibold text-sm">Quantidade: </label>
                        <input
                            type="number"
                            id="quantidade"
                            value={produto.quantidade}
                            onChange={(e) => setProduto({ ...produto, quantidade: e.target.value })}
                            className="border-[1px] rounded-md my-1 p-2"
                            disabled={loading}
                        />
                    </div>
                    <div className="flex flex-col w-1/4 ml-2">
                        <label htmlFor="valor" className="flex-col font-semibold text-sm">Valor Unitário: </label>
                        <input
                            type="number"
                            id="valor"
                            value={produto.valor}
                            onChange={(e) => setProduto({ ...produto, valor: e.target.value })}
                            className="border-[1px] rounded-md my-1 p-2"
                            disabled={loading}
                        />
                    </div>
                    <div className="flex-initial w-3/4">
                        <button
                            className={`bg-white border-2 border-soulog-900 text-soulog-900 text-sm 
                                font-semibold rounded-md ml-2 mt-4 px-5 py-2 
                                        ${loading ? "opacity-50 cursor-not-allowed" : null}`}
                            onClick={(e) => handlerAdicionaProduto(e)}
                            disabled={loading}
                        >
                            {loading ? (editProduto ? "Editando" : "Adicionando...") : (editProduto ? "Editar" : "Adicionar")}
                        </button>
                    </div>
                </div>
                <h3 className="font-bold text-lg mt-3">Lista de produtos</h3>

                {/*checked ? <Form /> : null*/}

                <TableConferirProdutosB2B
                    setChecked={checkItem}
                    setObjPedido={setObjPedido}
                    objPedido={objPedido}
                    setProduto={setProduto}
                    setEditProduto={setEditProduto}
                />
            </div>
        </>

    )
}
